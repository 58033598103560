import { Button, Col, Row } from "antd";
import { get } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import ModalAnt from "~/components/Antd/ModalAnt";
import { useFetchState } from "~/utils/helpers";
import apis from "../coupon.api";
import { CouponBase } from "../coupon.modal";
import TransferSaleChannel from "./TransferSaleChannel/index";
type propsType = {
  form: any;
  allowAllApply : CouponBase['allowAllApply'],
};
export default function FormItemSaleChannelForDivisionSection({
  form,
  allowAllApply,
}: propsType): React.JSX.Element {
  const [division, setDivision] = useState<"b2b" | "b2c" | "visitor" | null>();
  const query = useMemo(() => ({division}),[division]);
  const [saleChannels,loading] = useFetchState({api : apis.getSaleChannelOptionsByDivision,query,useDocs : false,shouldRun : !!division});
  
  const [open, setOpen] = useState(false);
  const onOpen = useCallback((d: "b2b" | "b2c" | "visitor") => {
    setDivision(d);
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setDivision(null);
    setOpen(false);
  }, []);
  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <Button disabled={allowAllApply?.b2b?.all} onClick={() => onOpen("b2b")} type="dashed" block>
            Chọn kênh bánh hàng cho b2b
          </Button>
        </Col>
        <Col span={8}>
          <Button disabled={allowAllApply?.b2c?.all} onClick={() => onOpen("b2c")} type="dashed" block>
            Chọn kênh bánh hàng cho b2c
          </Button>
        </Col>
        <Col span={8}>
          <Button disabled={allowAllApply?.visitor?.all} onClick={() => onOpen("visitor")} type="dashed" block>
            Chọn kênh bánh hàng cho Visitor
          </Button>
        </Col>
      </Row>
      <ModalAnt
        width={1000}
        open={open}
        onCancel={onClose}
        destroyOnClose
        // footer={null}
        footer={[<Button type="primary" onClick={onClose}>Xong</Button>]}
      >
        <TransferSaleChannel
          onChange={(newData: any) =>
            {
              form.setFieldsValue({
                allowAllApply : {
                  ...allowAllApply,
                  [division || ""]: {
                    ...form.getFieldValue(division),
                    saleChannels: newData,
                  },
                }
              })
            }
          }
          dataSource={division ? get(allowAllApply,[division, "saleChannels"]) : [] as any}
          dataSourceSearch={saleChannels}
          loading={loading}
        />
      </ModalAnt>
    </div>
  );
}
