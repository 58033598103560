import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tabs
} from "antd";
import dayjs from "dayjs";
import { get, omit, sumBy } from "lodash";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import BaseBorderBox from "~/components/common/BaseBorderBox";
import WithOrPermission from "~/components/common/WithOrPermission";
import {
  COMPONENT_MODES,
  REF_COLLECTION,
  TYPE_VOUCHER,
  WH_PAYMENT_METHOD,
  WH_PAYMENT_METHOD_VI,
} from "~/constants/defaultValue";
import { useGetProfile } from "~/modules/auth/auth.hook";
import { useGetCollaborator } from "~/modules/collaborator/collaborator.hook";
import SelectCollaborator from "~/modules/collaborator/components/SelectSearch";
import apiPaymentVoucher from "~/modules/paymentVoucher/paymentVoucher.api";
import { useGetPharmacyId } from "~/modules/pharmacy/pharmacy.hook";
import POLICIES from "~/modules/policy/policy.auth";
import SelectPharmacy_V2 from "~/modules/sale/bill/components/SelectPharmacy_V2";
import SelectVisitor from "~/modules/visitor/components/SelectSearch";
import { useGetVisitor } from "~/modules/visitor/visitor.hook";
import { PATH_APP } from "~/routes/allPath";
import useNotificationStore from "~/store/NotificationContext";
import { concatAddress } from "~/utils/helpers";
import AccountingDetails from "../../vouchers/components/AccountingDetailTable/AccountingDetailTable";
import {
  useCreatePaymentVoucher,
  useResetAction,
} from "../paymentVoucher.hook";
import { paymentVoucherSliceAction } from "../redux/reducer";
import "./form.scss";
const mainRowGutter = 24;
const FormItem = Form.Item;
const { TabPane } = Tabs;
const { Option } = Select;
type DataAccounting = {
  content?: string;
  debitAccount?: number;
  creditAccount?: number;
  amountOfMoney?: number;
};
type propsType = {
  onClose?: any;
  debt?: number | null;
  dataAccountingDefault?: DataAccounting[];
  method?: any;
  mutateOrderSupplier?: any;
};

export default function PaymentVoucherFormV2(
  props: propsType
): React.JSX.Element {
  useResetAction();
  const {
    onClose,
    debt,
    dataAccountingDefault,
    method = {
      type: "BILL",
    },
    mutateOrderSupplier,
  } = props;

  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const ref = useRef();
  const [accountingDetails, setAccountingDetails] = useState([]);
  const [initEmployee, setInitEmployee] = useState<any[]>([]);
  const profile = useGetProfile();
  const [partnerId, setPartnerId] = useState<any>(null);
  const [pharmacyId, setPharmacyId] = useState<any>(null);
  const [visitorId, setVisitorId] = useState<any>(null);
  
  const [pharmacy] = useGetPharmacyId(pharmacyId);
  const [partner] = useGetCollaborator(partnerId);
  const [visitor] = useGetVisitor(visitorId);
  const { onNotify } = useNotificationStore();

  const { nameLabel, refCollection, provider } = useMemo(() => {
    let nameLabel: string = "khách hàng B2B";
    let refCollection: any = "";
    let provider = null;
    switch (pathname) {
      case PATH_APP.vouchers.pharmacy:
        nameLabel = "khách hàng B2B";
        refCollection = REF_COLLECTION.PHARMA_PROFILE;
        provider = pharmacy;
        break;
      case PATH_APP.vouchers.partner:
        nameLabel = "khách hàng B2C";
        refCollection = REF_COLLECTION.PARTNER;
        provider = partner;
        break;
      case PATH_APP.vouchers.visitor:
        nameLabel = "khách hàng vãng lai";
        refCollection = REF_COLLECTION.VISITOR;
        provider = visitor;
        break;
      default:
        break;
    }
    return {
      nameLabel,
      refCollection,
      provider,
    };
  }, [pathname, pharmacy, partner,visitor]);

  //Hook
  const dispatch = useDispatch();
  const resetAction = () => {
    return dispatch(paymentVoucherSliceAction.resetAction());
  };
  const [isSubmitLoading, handleCreate] = useCreatePaymentVoucher(() => {
    onClose && onClose();
    mutateOrderSupplier && mutateOrderSupplier();
    resetAction();
  });

  const [issueNumber, setIssueNumber] = useState(null);
  const [dataAccounting, setDataAccounting] = useState(
    dataAccountingDefault ?? []
  );

  const fetchIssueNumber = async () => {
    const typeVoucher = TYPE_VOUCHER.PC;
    const res = await apiPaymentVoucher.postIssueNumber({ typeVoucher });
    setIssueNumber(res?.result);
    return res?.result;
  };

  useEffect(() => {
    if (provider) {
      form.setFieldsValue({
        name: provider?.name ?? provider?.fullName,
        receiver: provider?.name ?? provider?.fullName,
        provider: provider?._id,
        code:
          provider?.code ?? provider?.partnerNumber ?? provider?.employeeNumber,
        accountingDate: dayjs(),
        dateOfIssue: dayjs(),
        paymentMethod: WH_PAYMENT_METHOD.COD,
      });
      fetchIssueNumber().then((issueNumber) => {
        form.setFieldsValue({
          issueNumber,
        });
      });
      const address = concatAddress(provider?.address);
      form.setFieldsValue({ address: address });
    }
  }, [provider]);

  useEffect(() => {
    if (profile) {
      console.log(profile, "profile");
      form.setFieldsValue({
        employee: profile?.profile?.fullName,
        employeeId: profile?.profile?._id,
      });
    }
  }, [profile]);

  const onFinish = async (values: any) => {
    try {
      await form.validateFields();
      if (
        values?.reason?.trim() === "" ||
        values?.reason?.trim() === undefined
      ) {
        return onNotify?.error("Vui lòng lý do chi");
      }
      const fullValues = form.getFieldsValue(true);
      const { accountingDate, dateOfIssue } = fullValues;
      const newValue = {
        ...omit(values, ["code", "address", "name", "employee"]),
        accountingDate: dayjs(accountingDate).format("YYYY-MM-DD"),
        dateOfIssue: dayjs(dateOfIssue).format("YYYY-MM-DD"),
        accountingDetails: accountingDetails,
        totalAmount: sumBy([...accountingDetails], (item) =>
          get(item, "amountOfMoney", 0)
        ),
        method,
        refCollection,
        employeeId: profile?.profile?._id,
      };
      if (newValue?.totalAmount === 0) {
        return onNotify?.error("Vui lòng nhập số tiền");
      };
      handleCreate(newValue);
    } catch (error) {
      console.error(error);
    }
  };

  const SelectProvider = () => {
    let component;
    switch (pathname) {
      case PATH_APP.vouchers.pharmacy:
        component = (
          <SelectPharmacy_V2 onSelect={(e: any) => setPharmacyId(e)} />
        );
        break;
      case PATH_APP.vouchers.partner:
        component = (
          <SelectCollaborator onSelect={(e: any) => setPartnerId(e)} />
        );
        break;
      case PATH_APP.vouchers.visitor:
        component = <SelectVisitor onChange={(e: any) => setVisitorId(e)} />;
        break;
      default:
        break;
    }
    return component;
  };

  return (
    <div className="page-wraper">
      <div className="container-fluid">
        <Form
          autoComplete="off"
          form={form}
          labelAlign="left"
          labelCol={{ sm: 24, md: 24, lg: 4 }}
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
        >
          <Row
            align="top"
            className="staff-form__logo-row"
            gutter={mainRowGutter}
            justify="space-between"
          >
            <Col span={16}>
              <BaseBorderBox title={"Thông tin chung"}>
                <Row gutter={36}>
                  <Col span={14}>
                    <FormItem
                      label={`Chọn ${nameLabel}`}
                      labelCol={{ lg: 10 }}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: `Vui lòng chọn ${nameLabel}!`,
                        },
                      ]}
                    >
                      {SelectProvider()}
                    </FormItem>
                    <FormItem hidden name="provider"></FormItem>
                  </Col>
                  <Col span={10}>
                    <FormItem
                      label={`Mã ${nameLabel}`}
                      labelCol={{ lg: 14 }}
                      name="code"
                    >
                      <Input disabled />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem label="Người nhận" name="receiver">
                      {<Input />}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem label="Địa chỉ" name={"address"}>
                      <Input disabled />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem name="reason" label="Lý do chi">
                      {<Input />}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={36}>
                  <Col span={12}>
                    <FormItem
                      name="employee"
                      label="Nhân viên"
                      labelCol={{ lg: 8 }}
                    >
                      <Input disabled />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <Space>
                      <FormItem
                        labelCol={{ lg: 10 }}
                        label="Kèm theo"
                        name="originalDocument"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập số chứng từ kèm theo!",
                          },
                        ]}
                      >
                        <InputNumber min={0} />
                      </FormItem>
                      <p>chứng từ gốc</p>
                    </Space>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
            <Col span={8}>
              <BaseBorderBox title={"Chứng từ"}>
                <Row gutter={36}>
                  <Col span={24}>
                    <FormItem
                      label="Ngày hạch toán"
                      name="accountingDate"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn ngày hạch toán!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        placeholder="Ngày hạch toán"
                        disabledDate={(current) => {
                          return current > dayjs().endOf("day");
                        }}
                      />
                    </FormItem>
                    <FormItem
                      label="Ngày chứng từ"
                      name="dateOfIssue"
                      labelCol={{ lg: 8 }}
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn ngày chứng từ!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        placeholder="Ngày chứng từ"
                        disabledDate={(current) => {
                          return current > dayjs().endOf("day");
                        }}
                      />
                    </FormItem>

                    <FormItem
                      label="Số chứng từ"
                      name="issueNumber"
                      labelCol={{ lg: 8 }}
                    >
                      <Input disabled />
                    </FormItem>
                    <FormItem
                      label="Phương thức thanh toán"
                      name="paymentMethod"
                      labelCol={{ lg: 12 }}
                    >
                      <Select
                        style={{ width: "100%" }}
                        defaultValue={WH_PAYMENT_METHOD.COD}
                      >
                        <Option
                          value={WH_PAYMENT_METHOD.COD}
                          key={WH_PAYMENT_METHOD.COD}
                        >
                          {WH_PAYMENT_METHOD_VI.COD}
                        </Option>
                        <Option
                          value={WH_PAYMENT_METHOD.TRANSFER}
                          key={WH_PAYMENT_METHOD.TRANSFER}
                        >
                          {WH_PAYMENT_METHOD_VI.TRANSFER}
                        </Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </BaseBorderBox>
            </Col>
          </Row>
          <BaseBorderBox title={"Ghi chú"}>
            <Row>
              <Col span={24}>
                <FormItem label="Ghi chú" name={"note"} labelCol={{ lg: 8 }}>
                  <Input.TextArea />
                </FormItem>
              </Col>
            </Row>
          </BaseBorderBox>
          <Tabs
            // defaultActiveKey={'1'}
            destroyInactiveTabPane
            onChange={() => {}}
            // onTabClick={onTabClick}
          >
            <TabPane tab="Hạch toán" key={1}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <AccountingDetails
                  ref={ref}
                  mode={COMPONENT_MODES.EDIT}
                  dataSource={dataAccounting}
                  // whAppointment={whAppointment}
                  isShowSuggest={debt}
                  setAccountingDetails={setAccountingDetails}
                />
              </Space>
            </TabPane>
          </Tabs>
          <Row className="staff-form__submit-box">
            <WithOrPermission
              permission={[
                POLICIES.WRITE_VOUCHERPHARMACY,
                POLICIES.WRITE_VOUCHERSUPPLIER,
              ]}
            >
              <Button
                icon={<SaveOutlined />}
                type="primary"
                htmlType="submit"
                loading={isSubmitLoading}
              >
                Lưu
              </Button>
            </WithOrPermission>
            {isSubmitLoading ? (
              <Button disabled>Đóng</Button>
            ) : onClose ? (
              <Button icon={<CloseCircleOutlined />} onClick={onClose}>
                Đóng
              </Button>
            ) : (
              <Link to={"/supplier"}>
                <Button icon={<CloseCircleOutlined />}>Đóng</Button>
              </Link>
            )}
          </Row>
        </Form>
      </div>
    </div>
  );
}
