import { get } from "lodash";
import requester from "~/api/requester";

const apis = {
    getAll: (query?: any) => requester.get(`/api/v1/visitor`, query),
    getAuthenticated: (query?: any) => requester.get(`/api/v1/visitor-search`, query),
    getById: (id?: any) => requester.get(`/api/v1/visitor/${id}`),
    create: (data?: any) => requester.post(`/api/v1/visitor`, data),
    update: (data?: any) => requester.put(`/api/v1/visitor/${get(data,'_id')}`, data),
    delete: (id?: any) => requester.delete(`/api/v1/visitor/${id}`),
}
export default apis;
