import { Col, Flex, Form, Input, InputNumber, Radio, Rate, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormHeader from "~/components/common/Form/FormHeader";
import LangFormItem from "~/components/common/LangFormItem";
import Loading from "~/components/common/Loading/index";
import UploadImage from "~/components/common/Upload/UploadImage";
import { LANGUAGE, requireRules, requireRulesCustom } from "~/constants/defaultValue";
import Editors from "~/utils/Editors";
import { useFailedAnt } from "~/utils/hook";
import {
  useCreateRating,
  useGetRating,
  useResetRating,
  useUpdateRating,
} from "../rating.hook";
import { RatingBase } from "../rating.modal";

export default function RatingForm(): React.JSX.Element {
  const { onFinishFailed, ErrorComponent, setErrors } = useFailedAnt();

  const [form] = Form.useForm();
  const { id } = useParams();
  const [data, loading] = useGetRating(id);
  const [language, setLanguage] = useState<any>(LANGUAGE.VI);
  const [isSubmitLoading, onCreate] = useCreateRating(() => form.resetFields());
  const [, onUpdate] = useUpdateRating();
  const onFinish = (values: RatingBase) => {
    setErrors([]);
    if (id) {
      onUpdate({
        _id: id,
        ...values,
      });
    } else {
      onCreate({
        ...values,
      });
    }
  };
  useEffect(() => {
    if(data && id){
        form.setFieldsValue({
            ...data,
          });
    }
    return () => {
        form.resetFields();
    }
  }, [data, id]);
  useResetRating();
  return (
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 4 }}
      labelAlign="left"
      onFinishFailed={onFinishFailed}
    >
      <Loading loading={loading} />

      <FormHeader
        loading={isSubmitLoading}
        title={id ? "Cập nhật" : "Tạo mới" + " đánh giá"}
      />
      <Row justify="end" style={{ marginBlock: 20 }}>
        <Radio.Group
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <Radio.Button value={LANGUAGE.VI}>VI</Radio.Button>
          <Radio.Button value={LANGUAGE.EN}>EN</Radio.Button>
        </Radio.Group>
      </Row>
      <Row>
        <ErrorComponent mess={"Vui lòng kiểm tra cả TAB VI lẫn EN"} />

        <Col span={18}>
          <Form.Item rules={requireRules} name={"index"} label="Thứ tự">
            <InputNumber step={1} min={0} />
          </Form.Item>
          <LangFormItem
            rules={requireRulesCustom('Vui lòng nhập tiêu đề')}
            fieldName="title"
            label="Tiêu đề"
            language={language}
          >
            <Input />
          </LangFormItem>
          <LangFormItem
            rules={requireRulesCustom('Vui lòng nhập mô tả ngắn')}
            fieldName="subTitle"
            label="Mô tả ngắn"
            language={language}
          >
            <Input />
          </LangFormItem>
          <Form.Item rules={requireRules} name="rate" label="Điểm đánh giá">
            <Rate allowHalf />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Flex justify={"center"}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item name="image">
                  <UploadImage
                    title="Ảnh đại diện"
                    onChange={(image: string) => setFieldsValue({ image })}
                    imgUrl={getFieldValue("image")}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Flex>
        </Col>
      </Row>

      <LangFormItem
        rules={requireRulesCustom('Vui lòng nhập nội dung')}
        fieldName="content"
        label="Nội dung"
        language={language}
        labelCol={{ span: 3 }}
      >
        <Editors />
      </LangFormItem>
    </Form>
  );
}
