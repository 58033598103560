import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Flex,
  Popconfirm,
  Row,
  Spin,
  Tooltip,
  Typography
} from "antd";
import { ColumnsType } from "antd/es/table/InternalTable";
import { get } from "lodash";
import React from "react";
import TableAnt from "~/components/Antd/TableAnt";
import { getDataSourceEachVariant } from "~/modules/productPrice/productPrice.hook";
import { ProductPriceResponse } from "~/modules/productPrice/productPrice.modal";
import { variant } from "~/modules/sale/bill/bill.modal";
import {
  formatPercentage,
  formatter, getValueOfMathHaveZero,
  ObjOrString
} from "~/utils/helpers";
type propsType = {
  variant?: variant;
  dataPriceSaleChannel: ProductPriceResponse[];
  onOpen: (variantId?: string, dataUpdate?: ProductPriceResponse) => void;
  loading: boolean;
  onDelete: (_id: string) => void;
  loadingDelete: boolean;
  level : 1 | 2 | 3;
};
export default function PriceSaleChannelItem({
  variant,
  dataPriceSaleChannel,
  onOpen,
  loading,
  onDelete,
  loadingDelete,
  level,
}: propsType): React.JSX.Element {
  const columns: ColumnsType<ProductPriceResponse> = [
    {
      title: "Kênh bán hàng",
      dataIndex: "saleChannelId",
      key: "saleChannelId",
      render: (saleChannelId) => ObjOrString(get(saleChannelId, "title", "")),
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      align: "center",
      width: 150,
      render: (value, rc) => {
        
        if (rc?.valueType === "VALUE") return formatter(value);
        if(level === 2) return formatPercentage(rc?.value) + "%";
        const valueEffect = getValueOfMathHaveZero(
          variant?.price || 0,
          rc?.value,
          rc?.valueType
        );
        return (
          <Typography.Text>
            {formatPercentage(rc?.value)}% (
            <Tooltip
              placement="bottom"
              title={`Giá sau cùng (${formatter(
                variant?.price
              )}${formatPercentage(rc?.value)}%)`}
            >
              <Typography.Text type="secondary">
                {formatter((variant?.price || 0) + valueEffect)}
              </Typography.Text>
            </Tooltip>
            )
          </Typography.Text>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 100,
      render: (_id, record) => (
        <Flex justify={"center"} gap={10}>
          <Typography.Link onClick={() => onOpen(variant?._id, record)}>
            Sửa
          </Typography.Link>
          <Popconfirm title="Xác nhận xoá" onConfirm={() => onDelete(_id)}>
            {loadingDelete ? (
              <Spin spinning />
            ) : (
              <Typography.Link>Xoá</Typography.Link>
            )}
          </Popconfirm>
        </Flex>
      ),
    },
  ];
  return (
    <Row>
      {level === 3 && <>
      <Col span={4}>
        <Typography.Text>
          Đơn vị:{" "}
          <Typography.Text strong>
            {ObjOrString(variant?.unit?.name) || ""}
          </Typography.Text>
        </Typography.Text>
      </Col>
      <Col span={4}>
        <Typography.Text>
          Giá cơ sở:{" "}
          {
            <Typography.Text strong>
              {formatter(variant?.price) || 0}
            </Typography.Text>
          }
        </Typography.Text>
      </Col>
      </>}
      <Col flex={1}>
        <div>
          <Flex className="mb-2" justify={"space-between"}>
            <Typography.Text strong>Giá theo kênh</Typography.Text>
            <Button
              onClick={() => onOpen(variant?._id)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm kênh bán hàng
            </Button>
          </Flex>
          <TableAnt
            rowKey={(rc) => rc?._id}
            columns={columns}
            dataSource={getDataSourceEachVariant(
              variant?._id,
              dataPriceSaleChannel,
              level,
            )}
            loading={loading}
          />
        </div>
      </Col>
    </Row>
  );
}
