import { PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/lib/table/InternalTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TableAnt from "~/components/Antd/TableAnt";
import { filterSlug } from "~/utils/helpers";
import { DataTransfer } from "../../coupon.modal";
type propsType = {
  onRemove: (ids: any[]) => void;
  dataSource : DataTransfer[]
};
export default function Target({
  onRemove,
  dataSource,
}: propsType): React.JSX.Element {
  const [keyword,setKeyword] = useState('');
  const [data,setData] = useState<DataTransfer[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleRemoveMulti = useCallback(() => {
    onRemove(selectedRowKeys);
  }, [selectedRowKeys,dataSource]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (kw: string) => setKeyword(kw);
  useEffect(() => {
    if(keyword){
        setData(dataSource?.filter((item) => filterSlug(keyword,item?.title)));
    }else{  
        setData(dataSource)
    }
  },[keyword,dataSource]);
  const columns: ColumnsType = [
    {
      title: "Chọn tất cả",
      dataIndex: "title",
      key: "title",
      render(title) {
        return <Typography.Text strong>{title}</Typography.Text>;
      },
    },
    {
      title: selectedRowKeys?.length ? (
        <Button size="small" onClick={handleRemoveMulti} type="primary">
          Gỡ
        </Button>
      ) : (
        ""
      ),
      dataIndex: "_id",
      key: "_id",
      align: "end",
      width: 100,
      render(_id, record: any) {
        return (
          <Button
            type="primary"
            danger
            onClick={() => onRemove([_id])}
            icon={<PlusOutlined />}
            size="small"
          >
            Gỡ
          </Button>
        );
      },
    },
  ]
  return (
    <TableAnt
      title={() => (
        <Search allowClear onChange={(e) => onSearch(e.target.value)} />
      )}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      size="small"
      pagination={false}
      rowKey={(rc) => rc?._id}
      scroll={{ y: 400 }}
    />
  );
}
