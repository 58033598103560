import { REF_COLLECTION } from "~/constants/defaultValue";

export const STATUS_BILL = {
  NEW: "NEW",
  READY: "READY",
  UNREADY: "UNREADY",
  REQUESTED: "REQUESTED",
  REJECT: "REJECT",
  PACKAGE_EXPORT: "PACKAGE_EXPORT",
  SHIPPING: "SHIPPING",
  // PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  RETURN: "RETURN",
  CUSTOMER_REJECT: "CUSTOMER_REJECT",
};
export const STATUS_BILL_VI = {
  NEW: "Đã tiếp nhận",
  READY: 'Sẵn sàng xuất kho', 
  UNREADY: 'Không sẵn sàng xuất kho', 
  REQUESTED: 'Đã gửi yêu cầu xuất kho',
  REJECT: 'Đã từ chối',
  PACKAGE_EXPORT: 'Đang xuất kho',
  SHIPPING: 'Đang gửi hàng',
  // PROCESSING: "Đang xử lý",
  COMPLETED: "Đã hoàn thành",
  CANCELLED: "Đã huỷ",
  RETURN: "Đơn vị vận chuyển từ chối",
  CUSTOMER_REJECT: "Khách hàng từ chối nhận",
};

export const STATUS_BILL_LEVEL = {
  NEW: 1,
  REQUESTED: 2,
  PACKAGE_EXPORT: 3,
  SHIPPING: 4,
  COMPLETED: 5,
  CANCELLED: 6,
};

export const defaultFee = [
  {
    typeFee: 'SUB_FEE',
    typeValue: 'VALUE',
    value: 0
  },
  {
    typeFee: 'LOGISTIC',
    typeValue: 'VALUE',
    value: 0
  },
];


export const NAME_REF_COLLECTION : any= {
  PARTNER: 'B2C',
  PHARMA_PROFILE: 'B2B',
  VISITOR: 'Vãng lai',
  SUPPLIER: 'Nhà cung cấp',
}