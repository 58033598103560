import { Button, Col, Row } from 'antd';
import { differenceBy, unionBy, uniq, uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import BaseBorderBox from '~/components/common/BaseBorderBox/index';
import { DataTransfer } from '../../coupon.modal';
import Source from './Source';
import Target from './Target';
type propsType = {
  onChange : (newData : any[]) => void,
  dataSourceSearch : DataTransfer[],
  dataSource : DataTransfer[],
  loading : boolean,
}
export default function TransferSaleChannel({dataSourceSearch = [],onChange,dataSource = [],loading}:propsType) : React.JSX.Element {
    console.log(unionBy(dataSourceSearch,dataSource,'_id'),'dataSource');
    
    return (
        <div className="SelectSupplier m-0" style={{ minHeight: 520 }}>
  
        <Row
          gutter={16}
          wrap={false}
          style={{ boxSizing: "border-box", marginLeft: 0, marginRight: 0 }}
        >
          <Col span={12}>
            <BaseBorderBox
              styleContent={{ paddingLeft: 0, paddingRight: 0 }}
              title={
                <span>
                  Kênh chưa chọn
                </span>
              }
            >
              <div className="ServiceGroup">
                <Source loading={loading} dataSource={differenceBy(dataSourceSearch,dataSource,'_id')} onAdd={(data) => onChange([...dataSource,...data])} />
              </div>
            </BaseBorderBox>
          </Col>
          <Col  span={12}> 
            <BaseBorderBox
              styleContent={{ paddingLeft: 0, paddingRight: 0 }}
              title={"Kênh đã chọn"}
            >
              <Target onRemove={(data) => onChange(dataSource?.filter((item) => !data?.includes(item?._id)))} dataSource={dataSource}/>
            </BaseBorderBox>
          </Col>
        </Row>
      </div>
    )
}