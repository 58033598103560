import { PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/lib/table/InternalTable";
import { get } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TableAnt from "~/components/Antd/TableAnt";
import { filterSlug } from "~/utils/helpers";
import { DataTransfer } from "../../coupon.modal";
type propsType = {
  onAdd: (data: DataTransfer[]) => void;
  dataSource : DataTransfer[],
  loading : boolean
};
export default function Source({
  onAdd,
  dataSource,
  loading,
}: propsType): React.JSX.Element {
  const [keyword,setKeyword] = useState('');
  const [data,setData] = useState<DataTransfer[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddMulti = useCallback(() => {
      const dataAdd  : any = dataSource?.filter((data) => selectedRowKeys?.includes(data?._id));    
      console.log(dataAdd,'dataAdd');
      
      onAdd(dataAdd);
  }, [selectedRowKeys,dataSource]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (kw: string) => setKeyword(kw);
  useEffect(() => {
    if(keyword){
        setData(dataSource?.filter((item) => filterSlug(keyword,item?.title)));
    }else{  
        setData(dataSource)
    }
  },[keyword,dataSource]);
  const columns: ColumnsType = [
    {
      title: "Chọn tất cả",
      dataIndex: "title",
      key: "title",
      render(title) {
        return <Typography.Text strong>{title}</Typography.Text>;
      },
    },
    {
      title: selectedRowKeys?.length ? (
        <Button size="small" onClick={handleAddMulti} type="primary">
          Thêm
        </Button>
      ) : (
        ""
      ),
      dataIndex: "_id",
      key: "_id",
      align: "end",
      width: 100,
      render(_id, record: any, index) {
        return (
          <Button
            type="primary"
            onClick={() => onAdd([record])}
            icon={<PlusOutlined />}
            size="small"
          >
            Thêm
          </Button>
        );
      },
    },
  ]
  return (
    <TableAnt
      title={() => (
        <Search allowClear onChange={(e) => onSearch(e.target.value)} />
      )}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      loading={loading}
      size="small"
      pagination={false}
      rowKey={(rc) => rc?._id}
      scroll={{ y: 400 }}
    />
  );
}
