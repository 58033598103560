// Please UnComment To use

import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearQuerySearch, getExistProp } from "~/utils/helpers";
import {
  getSelectors,
  useFailed,
  useFetchByParam,
  useQueryParams,
  useResetState,
  useSubmit,
  useSuccess,
} from "~/utils/hook";
import { saleChannelSliceAction } from "./redux/reducer";
import { STATUS } from "~/constants/defaultValue";
const MODULE = "saleChannel";
const MODULE_VI = "kênh bán hàng";
const getSelector = (key: any) => (state: any) => state[MODULE][key];

const {
  loadingSelector,
  listSelector,
  getListFailedSelector,
  getByIdLoadingSelector,
  getByIdSelector,
  getByIdFailedSelector,
  deleteSuccessSelector,
  deleteFailedSelector,
  isSubmitLoadingSelector,
  createSuccessSelector,
  createFailedSelector,
  updateSuccessSelector,
  updateFailedSelector,
  pagingSelector,
} = getSelectors(MODULE);

const listProductByIdSelector = getSelector("listProductById");
const isLoadingListProductByIdSelector = getSelector(
  "isLoadingListProductById"
);
const getListProductByIdFailedSelector = getSelector(
  "getListProductByIdFailed"
);

const pagingListProductByIdSelector = getSelector("pagingListProductById");

const isSubmitLoadingListProductByIdSelector = getSelector(
  "isSubmitLoadingListProductById"
);

const addListProductByIdSuccessSelector = getSelector(
  "addListProductByIdSuccess"
);
const addListProductByIdFailedSelector = getSelector(
  "addListProductByIdFailed"
);

const removeListProductByIdSuccessSelector = getSelector(
  "removeListProductByIdSuccess"
);
const removeListProductByIdFailedSelector = getSelector(
  "removeListProductByIdFailed"
);
const updateListProductByIdSuccessSelector = getSelector(
  "updateListProductByIdSuccess"
);
const updateListProductByIdFailedSelector = getSelector(
  "updateListProductByIdFailed"
);

export const useGetListProductByIdSaleChannelPaging = () =>
  useSelector(pagingListProductByIdSelector);

export const useSaleChannelPaging = () => useSelector(pagingSelector);

export const useGetSaleChannel_onlyGet = () => [
  useSelector(getByIdSelector),
  useSelector(getByIdLoadingSelector),
];

export const useGetSaleChannels = (param: any) => {
  return useFetchByParam({
    action: saleChannelSliceAction.getListRequest,
    loadingSelector: loadingSelector,
    dataSelector: listSelector,
    failedSelector: getListFailedSelector,
    param,
  });
};
export const useGetSaleChannel = (id: any) => {
  return useFetchByParam({
    action: saleChannelSliceAction.getByIdRequest,
    loadingSelector: getByIdLoadingSelector,
    dataSelector: getByIdSelector,
    failedSelector: getByIdFailedSelector,
    param: id,
  });
};

export const useCreateSaleChannel = (callback?: any) => {
  useSuccess(
    createSuccessSelector,
    `Tạo mới ${MODULE_VI} thành công`,
    callback
  );
  useFailed(createFailedSelector);

  return useSubmit({
    action: saleChannelSliceAction.createRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useUpdateSaleChannel = (callback?: any) => {
  useSuccess(
    updateSuccessSelector,
    `Cập nhật ${MODULE_VI} thành công`,
    callback
  );
  useFailed(updateFailedSelector);

  return useSubmit({
    action: saleChannelSliceAction.updateRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useDeleteSaleChannel = (callback?: any) => {
  useSuccess(deleteSuccessSelector, `Xoá ${MODULE_VI} thành công`, callback);
  useFailed(deleteFailedSelector);

  return useSubmit({
    action: saleChannelSliceAction.deleteRequest,
    loadingSelector: isSubmitLoadingSelector,
  });
};

export const useSaleChannelQueryParams = () => {
  const query = useQueryParams();
  const limit = query.get("limit") || 10;
  const page = query.get("page") || 1;
  const keyword = query.get("keyword");
  const status = query.get("status");

  const createSuccess = useSelector(createSuccessSelector);
  const updateSuccess = useSelector(updateSuccessSelector);
  const deleteSuccess = useSelector(deleteSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      page,
      limit,
      keyword,
      status,
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    page,
    limit,
    keyword,
    status,
    createSuccess,
    updateSuccess,
    deleteSuccess,
  ]);
};

export const useUpdateSaleChannelParams = (
  query: any,
  listOptionSearch?: any[]
) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [keyword, setKeyword] = useState(get(query, "keyword"));
  useEffect(() => {
    setKeyword(get(query, "keyword"));
  }, [query]);
  const onParamChange = (param: any) => {
    // Clear Search Query when change Params
    clearQuerySearch(listOptionSearch, query, param);

    if (!param.page) {
      query.page = 1;
    }

    // Convert Query and Params to Search Url Param
    const searchString = new URLSearchParams(
      getExistProp({
        ...query,
        ...param,
      })
    ).toString();

    // Navigate
    navigate(`${pathname}?${searchString}`);
  };

  return [keyword, { setKeyword, onParamChange }];
};
export const useInitSaleChannel = (saleChannel: any, id: any) => {
  return useMemo(() => {
    if (!saleChannel || !id) {
      return { status: true };
    }

    return {
      ...saleChannel,
      status: saleChannel.status === STATUS.ACTIVE ? true : false,
    };
  }, [saleChannel, id]);
};
export const useResetSaleChannelAction = () => {
  useResetState(saleChannelSliceAction.resetAction);
};

// LÍT PRODUCT BY ID
export const useSearchProductByIdGetSaleChannelsQueryParam = ({
  supplierSearchId,
  id,
  keywordSearch,
  productGroupSearchId,
}: {
  supplierSearchId : any,
  id : any,
  keywordSearch : any,
  productGroupSearchId : any,
}) => {
  const addListProductByIdSuccess = useSelector(addListProductByIdSuccessSelector);
  const removeListProductByIdSuccess = useSelector(removeListProductByIdSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      supplierIds: supplierSearchId,
      id,
      keyword:keywordSearch,
      productGroupIds : productGroupSearchId
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    supplierSearchId,
    id,
    keywordSearch,
    productGroupSearchId,
    addListProductByIdSuccess,
    removeListProductByIdSuccess,
  ]);
};
export const useListProductByIdGetSaleChannelsQueryParam = ({
  supplierFilterId,
  id,
  keywordList,
  paging,
  productGroupFilterId,
}: {
  supplierFilterId : any,
  id : any,
  keywordList : any,
  paging : any,
  productGroupFilterId : any,
}) => {
  const addListProductByIdSuccess = useSelector(addListProductByIdSuccessSelector);
  const removeListProductByIdSuccess = useSelector(removeListProductByIdSuccessSelector);
  return useMemo(() => {
    const queryParams = {
      supplierIds: supplierFilterId,
      id,
      keyword:keywordList,
      productGroupIds : productGroupFilterId,
      ...paging
    };
    return [queryParams];
    //eslint-disable-next-line
  }, [
    supplierFilterId,
    id,
    keywordList,
    paging,
    productGroupFilterId,
    addListProductByIdSuccess,
    removeListProductByIdSuccess,
  ]);
};
export const useListProductByIdGetSaleChannels = (param: any) => {
  return useFetchByParam({
    action: saleChannelSliceAction.getListProductByIdRequest,
    loadingSelector: isLoadingListProductByIdSelector,
    dataSelector: listProductByIdSelector,
    failedSelector: getListProductByIdFailedSelector,
    param,
  });
};

export const useAddListProductByIdSaleChannel = () => {
  useSuccess(addListProductByIdSuccessSelector, `Thêm sản phẩm thành công`);
  useFailed(addListProductByIdFailedSelector);

  return useSubmit({
    action: saleChannelSliceAction.addListProductByIdRequest,
    loadingSelector: isSubmitLoadingListProductByIdSelector,
  });
};
export const useRemoveListProductByIdSaleChannel = () => {
  useSuccess(removeListProductByIdSuccessSelector, `Gỡ sản phẩm thành công`);
  useFailed(removeListProductByIdFailedSelector);

  return useSubmit({
    action: saleChannelSliceAction.removeListProductByIdRequest,
    loadingSelector: isSubmitLoadingListProductByIdSelector,
  });
};
export const useUpdateListProductByIdSaleChannel = () => {
  useSuccess(updateListProductByIdSuccessSelector, `Cập nhật sản phẩm thành công`);
  useFailed(updateListProductByIdFailedSelector);

  return useSubmit({
    action: saleChannelSliceAction.updateListProductByIdRequest,
    loadingSelector: isSubmitLoadingListProductByIdSelector,
  });
};
