import { Button, Checkbox, Col, Divider, Flex, Form, Row, Select } from "antd";
import React, { useMemo } from "react";
import DebounceSelectMultipleItemCustomer from "~/components/common/DebounceSelectMultiple/DebounceSelectMultipleItemCustomer";
import { DebounceSelectMultipleProvider } from "~/components/common/DebounceSelectMultiple/DebounceSelectMultipleProvider";
import { requireRulesCustom } from "~/constants/defaultValue";
import FormItemSaleChannelForDivisionSection from "./FormItemSaleChannelForDivisionSection";
type propsType = {
  customerApplyIds: any;
  allowAllApply: any;
  form : any
};
export default function CustomerApplyFormItem({customerApplyIds,allowAllApply,form }: propsType): React.JSX.Element {

  const valuesPharmacy = useMemo(() => customerApplyIds
  ?.filter((item : any) => item?.refCollection === "pharma_profile")
  ?.map((item: any) => item?.id),[customerApplyIds]);

  const valuesPartner = useMemo(() => customerApplyIds
  ?.filter((item : any) => item?.refCollection === "partner")
  ?.map((item: any) => item?.id),[customerApplyIds]);

  const valuesVisitor = useMemo(() => customerApplyIds
  ?.filter((item : any) => item?.refCollection === "visitor")
  ?.map((item: any) => item?.id),[customerApplyIds]);

  return (
    <>
      <Form.Item style={{marginBottom : 'unset'}} label="Áp dụng cho mọi khách hàng">
        <Form.Item shouldUpdate={(p,n) => p?.allowAllApply !== n?.allowAllApply} noStyle>
          {({getFieldValue}) =>   <Flex gap={20}>
        <Form.Item
          name={["allowAllApply", "all"]}
          valuePropName="checked"
        >
          <Checkbox>Mọi đối tượng</Checkbox>
        </Form.Item>
        <Form.Item
          name={["allowAllApply", "b2b","all"]}
          valuePropName="checked"
        >
          <Checkbox disabled={!!getFieldValue(['allowAllApply','all'])}>B2B</Checkbox>
        </Form.Item>
        <Form.Item
          name={["allowAllApply", "b2b","isAvailability"]}
          hidden
        />
        <Form.Item
          name={["allowAllApply", "b2b","saleChannels"]}
          hidden
        />
        <Form.Item
          name={["allowAllApply", "b2c","all"]}
          valuePropName="checked"
        >
          <Checkbox disabled={!!getFieldValue(['allowAllApply','all'])}>B2C</Checkbox>
        </Form.Item>
        <Form.Item
          name={["allowAllApply", "b2c","isAvailability"]}
          hidden
        />
        <Form.Item
          name={["allowAllApply", "b2c","saleChannels"]}
          hidden
        />
        <Form.Item
          name={["allowAllApply", "visitor","all"]}
          valuePropName="checked"
        >
          <Checkbox disabled={!!getFieldValue(['allowAllApply','all'])}>Visitor</Checkbox>
        </Form.Item>
        <Form.Item
          name={["allowAllApply", "visitor","isAvailability"]}
          hidden
        />
        <Form.Item
          name={["allowAllApply", "visitor","saleChannels"]}
          hidden
        />
        </Flex>}
        </Form.Item>
      </Form.Item>
      <Divider/>
      <FormItemSaleChannelForDivisionSection allowAllApply={allowAllApply} form={form}/>
      <DebounceSelectMultipleProvider
        usePharmacy
        usePartner
        useVisitor
        valuesPharmacy={valuesPharmacy}
        valuesPartner={valuesPartner}
        valuesVisitor={valuesVisitor}
      >
        
        <Form.Item
          shouldUpdate={(p, n) => p?.customerApplyIds !== n?.customerApplyIds}
          noStyle
        >
          {({ getFieldValue }) => (
            <Form.Item
              labelCol={{ span: 24 }}
              label="Các khách hàng được phép dùng:"
            >
              <Form.List name={"customerApplyIds"}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => {
                      const refCollection = getFieldValue([
                        "customerApplyIds",
                        index,
                        "refCollection",
                      ]);
                      return (
                        <Row gutter={16}>
                          <Col span={6}>
                            <Form.Item name={[index, "refCollection"]}>
                              <Select
                                disabled={!!refCollection}
                                allowClear
                                placeholder="Loại khách hàng"
                                options={[
                                  {
                                    label: "Nhà thuốc",
                                    value: "pharma_profile",
                                  },
                                  {
                                    label: "Cộng tác viên",
                                    value: "partner",
                                  },
                                  {
                                    label: "Khách hàng vãng lai",
                                    value: "visitor",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col flex={1}>
                            <Form.Item shouldUpdate>
                              {() => (
                                <Form.Item
                                  rules={requireRulesCustom(
                                    "Vui lòng chọn người dùng mã"
                                  )}
                                  name={[index, "id"]}
                                >
                                  <DebounceSelectMultipleItemCustomer
                                    refCollection={refCollection}
                                  />
                                </Form.Item>
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Button
                              onClick={() => remove(index)}
                              danger
                              type="primary"
                            >
                              Gỡ
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}

                    <Flex gap={20} justify="space-between">
                      <Button
                        type="dashed"
                        block
                        onClick={() => add({ refCollection: "pharma_profile" })}
                        disabled={allowAllApply?.b2b?.all || allowAllApply?.all}
                      >
                        Thêm nhà thuốc
                      </Button>
                      <Button
                        type="dashed"
                        block
                        onClick={() => add({ refCollection: "partner" })}
                        disabled={allowAllApply?.b2c?.all || allowAllApply?.all}
                      >
                        Thêm cộng tác viên
                      </Button>
                      <Button
                        type="dashed"
                        block
                        onClick={() => add({ refCollection: "visitor" })}
                        disabled={allowAllApply?.visitor?.all || allowAllApply?.all}
                      >
                        Thêm khách hàng vãng lai
                      </Button>
                    </Flex>
                  </>
                )}
              </Form.List>
            </Form.Item>
          )}
        </Form.Item>
      </DebounceSelectMultipleProvider>
    </>
  );
}
