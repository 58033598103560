import { put, call, takeLatest } from 'redux-saga/effects';
import api from '../rating.api'; 
import { ratingActions } from './reducer';

function* getListRating({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(ratingActions.getListSuccess(data));
    yield put(ratingActions.clearAction());
  } catch (error:any) {
    yield put(ratingActions.getListFailed(error));
  }
}

function* getByIdRating({payload:id} : any) : any {
  try {
    const data = yield call(api.getById,id);
    yield put(ratingActions.getByIdSuccess(data));
  } catch (error:any) {
    yield put(ratingActions.getByIdFailed(error));
  }
}

function* createRating({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(ratingActions.createSuccess(data));
  } catch (error:any) {
    yield put(ratingActions.createFailed(error));
  }
}

function* updateRating({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(ratingActions.updateSuccess(data));
  } catch (error:any) {
    yield put(ratingActions.updateFailed(error));
  }
}
function* deleteRating({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(ratingActions.deleteSuccess(data));
  } catch (error:any) {
    yield put(ratingActions.deleteFailed(error));
  }
}


export default function* ratingSaga() {
  yield takeLatest(ratingActions.getListRequest, getListRating);
  yield takeLatest(ratingActions.getByIdRequest, getByIdRating);
  yield takeLatest(ratingActions.createRequest, createRating);
  yield takeLatest(ratingActions.updateRequest, updateRating);
  yield takeLatest(ratingActions.deleteRequest, deleteRating);
}
