import cumulativeDiscountValueApi from "./cumulativeDiscountValue.api";
import * as cumulativeDiscountValueHook from "./cumulativeDiscountValue.hook";
import * as cumulativeDiscountValueService from "./cumulativeDiscountValue.service";
import * as cumulativeDiscountValueModels from "./cumulativeDiscountValue.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        // index : CumulativeDiscountValueScreen,
    },
    api : cumulativeDiscountValueApi,
    hook : cumulativeDiscountValueHook,
    service : cumulativeDiscountValueService,
    model : cumulativeDiscountValueModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;