import { GiftTwoTone, UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Skeleton,
  Tabs,
} from "antd";
import { compact, concat, debounce, get, keys } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BaseBorderBox from "~/components/common/BaseBorderBox/index";
import RenderLoading from "~/components/common/RenderLoading";
import {
  InitValueFormProduct,
  LANGUAGE,
  PRODUCT_TYPE,
  PRODUCT_TYPE_VI,
  SALE_LEVEL,
  SALE_LEVEL_VI,
} from "../constants";
import {
  useCreateProduct,
  useGetProduct,
  useResetAction,
  useUpdateProduct,
} from "../product.hook";
import { FieldTypeFormProduct, TypePropsFormProduct } from "../product.modal";
import { convertInitProduct, convertSubmitData } from "../product.service";
import MedicineName from "./MedicineName";
import SelectCountry from "./SelectCountry";
import SelectManufacturer from "./SelectManufacturer";
import SelectProductGroup from "./SelectProductGroup";
import Variants from "./Variants";
import CumulativeDiscountModule from "~/modules/cumulativeDiscount";
import { useSupplierInfoRedux } from "~/modules/productsAll/productsAll.hook";
import { useDispatch } from "react-redux";
import { productActions } from "../redux/reducer";

import TabPane from "antd/es/tabs/TabPane";
import useNotificationStore from "~/store/NotificationContext";
import UploadImage from "~/components/common/Upload/UploadImage";
import ImagesProduct from "./ImagesProduct";
import TextArea from "antd/es/input/TextArea";
import LangFormItem from "~/components/common/LangFormItem";
import Editors from "~/utils/Editors";
import PriceSaleChannel from "./PriceSaleChannel";

const CLONE_PRODUCT_TYPE_VI: any = PRODUCT_TYPE_VI;
const CLONE_SALE_LEVEL_VI: any = SALE_LEVEL_VI;
const CLONE_TARGET_TYPE: any = CumulativeDiscountModule.constants.TARGET_TYPE;
const layoutRow = {
  gutter: 16,
};
export default function FormProduct({
  supplierId: supplierIdInit,
  id,
  onCancel,
  onUpdate,
  setSupplierId,
  setStep,
}: TypePropsFormProduct): React.JSX.Element {
  const [language, setLanguage] = useState(LANGUAGE.VI);
  // const supplierInfo = useSupplierInfoRedux();
  const { onNotify } = useNotificationStore();
  const [form] = Form.useForm();
  const [backupForm, setBackupForm] = useState<FieldTypeFormProduct[]>([]);
  const dispatch = useDispatch();
  const resetAction = () => {
    return dispatch(productActions.resetAction());
  };
  const [isSubmitLoading, onCreate] = useCreateProduct(() => {
    onCancel && onCancel();
    resetAction();
    setSupplierId && setSupplierId(null);
    setStep && setStep(0);
  });
  // const [, onUpdateProduct] = useUpdateProduct(() => {
  //   onCancel();
  //   resetAction();
  //   setSupplierId && setSupplierId(null);
  //   setStep &&  setStep(0);
  // });
  const [product, isLoading] = useGetProduct(id);
  const supplierId = useMemo(
    () => (!id ? supplierIdInit : get(product, "supplierId")),
    [id, product]
  );
  useResetAction();

  const onUndoForm = (isLast = false) => {
    // Action Back One step to set Form And Remove last Recover
    const stepUndo = backupForm.length === 1 || isLast ? 1 : 2;

    const preForm = backupForm[backupForm.length - stepUndo];
    form.setFieldsValue(preForm);
    const newRecoverForm = [...backupForm];

    newRecoverForm.pop();
    setBackupForm(newRecoverForm);
  };

  const onFinish = (values: FieldTypeFormProduct) => {
    const submitData = convertSubmitData({ values, supplierId });

    if (id) {
      if (onUpdate) {
        onUpdate({ ...submitData, _id: id });
      }
    } else {
      onCreate(submitData);
    }
  };

  const optionsType = useMemo(
    () =>
      keys(PRODUCT_TYPE).map((key) => ({
        label: CLONE_PRODUCT_TYPE_VI[key],
        value: key,
      })),
    []
  );
  const optionsSaleLevel = useMemo(
    () =>
      keys(SALE_LEVEL).map((key) => ({
        label: CLONE_SALE_LEVEL_VI[key],
        value: key,
      })),
    []
  );

  useEffect(() => {
    if (product && id) {
      const initProduct = convertInitProduct(product);

      form.setFieldsValue(initProduct);
      setBackupForm([initProduct]);
    } else {
      setBackupForm([form.getFieldsValue()]);
    }
  }, [product, id, form]);

  const onValuesChange = (valueChange: any, values: FieldTypeFormProduct) => {
    const key: keyof FieldTypeFormProduct = Object.keys(
      valueChange
    )[0] as keyof FieldTypeFormProduct;
    switch (key) {
      case "cumulativeDiscount":
        const cumulativeDiscount =
          CumulativeDiscountModule.service.onDiscountChange(values[key]);
        form.setFieldsValue({
          cumulativeDiscount,
        });
        break;
      default:
        break;
    }
    // Recover Form
    const onSetRecoverForm = () =>
      setBackupForm((pre: FieldTypeFormProduct[]) => [...pre, values]);
    const debounceSetRecover = debounce(onSetRecoverForm, 0);
    debounceSetRecover();
  };

  // useEffect(() => {
  //   if(dataNotificationUndo.open && !!dataNotificationUndo.description){
  //     notification.warning({
  //       message : `Hệ thống thông báo`,
  //       description : dataNotificationUndo.description,
  //       duration: 0, // Never Off
  //       btn : <Button size="small" onClick={() => onUndoForm(true)}>
  //         Hoàn tác
  //       </Button>
  //     });
  //     setDataNotificationUndo({
  //       open : false,
  //       description : null
  //     })
  //   }

  // },[dataNotificationUndo]);
  const [activeTab, setActiveTab] = useState("1");
  const onChangeTab = async (key: string) => {
    CumulativeDiscountModule.service.validateDiscount({
      form,
      onSuccess: () => setActiveTab(key),
      onFailed: () =>
        onNotify?.error("Vui lòng kéo xuống kiểm tra lại chiết khấu"),
    });
  };
  return (
    <div>
      <h5>{id ? "Cập nhật thuốc" : "Tạo mới thuốc"}</h5>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
        scrollToFirstError={true}
        initialValues={InitValueFormProduct}
        onValuesChange={onValuesChange}
      >
        <Row justify="end" style={{ marginBlock: 20 }}>
          <Radio.Group
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <Radio.Button value={LANGUAGE.VI}>VI</Radio.Button>
            <Radio.Button value={LANGUAGE.EN}>EN</Radio.Button>
          </Radio.Group>
        </Row>
        <Form.Item<FieldTypeFormProduct> name="medicalCode" hidden />

        <BaseBorderBox title={"Thông tin thuốc"}>
          <Row {...layoutRow}>
            <Col span={12}>
              {/* Tên thuốc */}
              {RenderLoading(
                isLoading,
                <MedicineName language={language} form={form} />
              )}
              <Form.Item<FieldTypeFormProduct> label="Hình thức" name="type">
                {RenderLoading(isLoading, <Select options={optionsType} />)}
              </Form.Item>
              <Form.Item<FieldTypeFormProduct>
                label="Mức độ đẩy hàng"
                name="saleLevel"
              >
                {RenderLoading(
                  isLoading,
                  <Select options={optionsSaleLevel} />
                )}
              </Form.Item>
              <Form.Item<FieldTypeFormProduct>
                label="Mã sản phẩm"
                name="codeBySupplier"
                tooltip="Mã dành cho nhà cung cấp"
                rules={[
                  { required: true, message: "Vui lòng nhập mã sản phẩm" },
                ]}
              >
                {RenderLoading(isLoading, <Input />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <ImagesProduct isLoading={isLoading} />
            </Col>
            {/* <Col span={12}>
              <Form.Item<FieldTypeFormProduct> label="Hình thức" name="type">
                {RenderLoading(isLoading, <Select options={optionsType} />)}
              </Form.Item>
            </Col> */}
          </Row>
          {/* <Row {...layoutRow}>
            <Col span={12}>
              <Form.Item<FieldTypeFormProduct>
                label="Mức độ đẩy hàng"
                name="saleLevel"
              >
                {RenderLoading(
                  isLoading,
                  <Select options={optionsSaleLevel} />
                )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item<FieldTypeFormProduct>
                label="Mã sản phẩm"
                name="codeBySupplier"
                tooltip="Mã dành cho nhà cung cấp"
                rules={[
                  { required: true, message: "Vui lòng nhập mã sản phẩm" },
                ]}
              >
                {RenderLoading(isLoading, <Input />)}
              </Form.Item>
            </Col>
          </Row> */}
        </BaseBorderBox>

        <BaseBorderBox title={"Thông tin chung"}>
          <Row {...layoutRow}>
            <Col span={12}>
              <LangFormItem
                language={language}
                label="Quy cách đóng gói"
                fieldName={["productDetail", "package"]}
              >
                {RenderLoading(isLoading, <Input />)}
              </LangFormItem>
            </Col>
            <Col span={12}>
              <LangFormItem
                language={language}
                label="Hoạt chất"
                fieldName={["productDetail", "element"]}
              >
                {RenderLoading(isLoading, <Input />)}
              </LangFormItem>
            </Col>
          </Row>
          <Row {...layoutRow}>
            <Col span={12}>
              <SelectCountry isLoading={isLoading} />
            </Col>
            <Col span={12}>
              <SelectManufacturer isLoading={isLoading} product={product} />
            </Col>
          </Row>

          <Row {...layoutRow}>
            <Col span={12}>
              <SelectProductGroup isLoading={isLoading} />
            </Col>
            <Col span={12}>
              <LangFormItem
                language={language}
                label="Dạng bào chế"
                fieldName={["productDetail", "dosageForm"]}
              >
                {RenderLoading(isLoading, <Input />)}
              </LangFormItem>
            </Col>
          </Row>
        </BaseBorderBox>

        <BaseBorderBox title={"Nội dung"}>
          <LangFormItem
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            label="Mô tả"
            fieldName={["content", "desc"]}
            language={language}
          >
            {RenderLoading(isLoading, <Editors />)}
          </LangFormItem>
          <LangFormItem
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            label="Mô tả ngắn"
            language={language}
            fieldName={["content", "descShort"]}
          >
            {RenderLoading(isLoading, <Editors />)}
          </LangFormItem>
          <LangFormItem
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            label="Công dụng"
            language={language}
            fieldName={["content", "use"]}
          >
            {RenderLoading(isLoading, <Editors />)}
          </LangFormItem>
          <LangFormItem
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            label="Thành phần"
            language={language}
            fieldName={["content", "component"]}
          >
            {RenderLoading(isLoading, <Editors />)}
          </LangFormItem>
          <LangFormItem
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            label="Tác dụng"
            language={language}
            fieldName={["content", "effect"]}
          >
            {RenderLoading(isLoading, <Editors />)}
          </LangFormItem>
          <LangFormItem
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            label="Tác dụng phụ"
            language={language}
            fieldName={["content", "subEffect"]}
          >
            {RenderLoading(isLoading, <Editors />)}
          </LangFormItem>
          <LangFormItem
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            label="Cách sử dụng"
            language={language}
            fieldName={["content", "usageInstructions"]}
          >
            {RenderLoading(isLoading, <Editors />)}
          </LangFormItem>
          <LangFormItem
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            label="Lưu ý"
            language={language}
            fieldName={["content", "note"]}
          >
            {RenderLoading(isLoading, <Editors />)}
          </LangFormItem>
        </BaseBorderBox>

        <BaseBorderBox title={"Đơn vị"}>
          <Col style={{ paddingBottom: 10 }} span={24}>
            <Tabs defaultActiveKey="variants">
              <Tabs.TabPane key={'variants'} tab="Đơn vị">
              <Variants form={form} isLoading={isLoading} />  
              </Tabs.TabPane>
              <Tabs.TabPane key={'priceSaleChannelLevel3'} tab="Giá theo kênh từng đơn vị">
                <PriceSaleChannel level={3} productId={get(product,'_id')} variants={get(product,'variants',[])}/>
              </Tabs.TabPane>
              {/* Not Use Now */}
              {/* <Tabs.TabPane key={'priceSaleChannelLevel2'} tab="Giá theo kênh chung cho sản phẩm">
                <PriceSaleChannel level={2} productId={get(product,'_id')}/>
              </Tabs.TabPane> */}
            </Tabs>
          </Col>
        </BaseBorderBox>

        <BaseBorderBox
          title={
            <span>
              Chiết khấu <GiftTwoTone />
            </span>
          }
        >
          <Tabs
            onChange={(e) => onChangeTab(e)}
            // destroyInactiveTabPane
            activeKey={activeTab}
            defaultActiveKey="1"
          >
            <TabPane key={"1"} tab="Chiết khấu bán hàng">
              <CumulativeDiscountModule.components.DiscountList
                supplierId={supplierId}
                target={CumulativeDiscountModule.constants.TARGET.product}
                targetType={CLONE_TARGET_TYPE.pharmacy}
                loading={isLoading}
                form={form}
              />
            </TabPane>
            <TabPane key={"2"} tab="Chiết khấu mua hàng">
              <CumulativeDiscountModule.components.DiscountList
                supplierId={supplierId}
                target={CumulativeDiscountModule.constants.TARGET.product}
                targetType={CLONE_TARGET_TYPE.supplier}
                loading={isLoading}
                form={form}
              />
            </TabPane>
          </Tabs>
        </BaseBorderBox>

        <Row justify={"end"} gutter={16}>
          <Col>
            {/* To preserve backup Keep one To undo to Init */}
            <Button
              disabled={backupForm.length <= 1}
              onClick={() => onUndoForm()}
            >
              Hoàn tác
            </Button>
          </Col>
          <Col>
            <Button onClick={onCancel}>Huỷ</Button>
          </Col>
          <Col>
            <Button loading={isSubmitLoading} htmlType="submit" type="primary">
              {id ? "Cập nhật" : "Tạo mới"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
