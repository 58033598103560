import {
  Badge,
  Button,
  Checkbox,
  Drawer,
  Flex,
  Popover,
  Select,
  Statistic,
  Typography,
} from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/lib/table/InternalTable";
import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import TableAnt from "~/components/Antd/TableAnt";
import BaseBorderBox from "~/components/common/BaseBorderBox/index";
import BtnAdd from "~/components/common/Layout/List/Header/BtnAdd";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import ImageProduct from "~/modules/sale/bill/components/ImageProduct";
import { filterSlug, formatter } from "~/utils/helpers";
import { SECTION_KEY } from "../constants";
import {
  useDeleteProductSection,
  useGetProductSection,
  useGetSection_onlyGet,
  useUpdateProductSection,
} from "../section.hook";
import { ProductInSection } from "../section.modal";
import SectionProductSearch from "./SectionProductSearch";
import CountUp from "react-countup";
import ListCouponInProduct from "~/modules/coupon/components/ListCouponInProduct";
import { GiftTwoTone } from "@ant-design/icons";

type propsType = {
  sectionId?: any;
};
const options = [
  { label: "Tất cả", value: "all" },
  { label: "Có", value: true },
  { label: "Không", value: false },
];

export default function SectionProductControl({
  sectionId,
}: propsType): React.JSX.Element {
  const [section] = useGetSection_onlyGet();
  const [filter, setFilter] = useState<any>({
    highlight: "all",
    isPopup: "all",
  });
  const isSectionHightLight = useMemo(
    () => get(section, "sectionKey") === SECTION_KEY.SECTION_HIGHLIGHT,
    [section]
  );

  const [productSection, loading]: [ProductInSection[], boolean] =
    useGetProductSection(sectionId);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [dataClone, setDataClone] = useState<ProductInSection[]>([]);
  const [keyword, setKeyword] = useState<any>();
  const [isSubmitLoading, update] = useUpdateProductSection();
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_SECTIONPRODUCT);
  const [, onDelete] = useDeleteProductSection();
  const onCheckedIsPopup = (e: any, _id: any) => {
    const checked = e.target.checked;
    const submitData = {
      _id,
      isPopup: checked,
    };
    update(submitData);
  };

  const onCheckedHighlight = (e: any, _id: any) => {
    const checked = e.target.checked;
    const submitData = {
      _id,
      highlight: checked,
    };
    update(submitData);
  };

  const sumHightLight = useMemo(
    () =>
      productSection?.reduce((sum, cur) => (cur?.highlight ? sum + 1 : sum), 0),
    [productSection]
  );
  const sumIsPopup = useMemo(
    () =>
      productSection?.reduce((sum, cur) => (cur?.isPopup ? sum + 1 : sum), 0),
    [productSection]
  );

  const columns: ColumnsType = [
    {
      title: "",
      dataIndex: "productId",
      key: "images",
      width: 80,
      render: (productId, rc) => (
        <Flex gap={5} align={"center"}>
          <ImageProduct
            styleImg={{ height: 50, width: 50 }}
            images={get(productId, "images")}
          />
          <Popover
            content={<ListCouponInProduct dataSource={rc?.coupons || []} />}
            trigger={["click"]}
          >
            <Badge size="small" count={rc?.coupons?.length}>
              <Button
                icon={<GiftTwoTone />}
                type="text"
                size="small"
                ghost
              ></Button>
            </Badge>
          </Popover>
        </Flex>
      ),
    },
    {
      title: "Tên",
      dataIndex: "productId",
      key: "name",
      render: (productId, rc) =>
        get(productId, "codeBySupplier") + " - " + get(productId, "name", ""),
    },
    {
      title: "Giá",
      dataIndex: "finalPrice",
      key: "finalPrice",
      align: "center",
      width: 130,
      render: (finalPrice, rc) => (
        finalPrice < get(rc, "price", 0) ? (
          <Typography.Text strong type="danger">
            {formatter(finalPrice)}{" "}
            <Typography.Text
              style={{ textDecoration: "line-through", fontSize: 12 }}
            >
              {formatter(get(rc, "price", 0))}
            </Typography.Text>
          </Typography.Text>
        ) : (
          <Typography.Text strong>
            {formatter(get(rc, "price", 0))}
          </Typography.Text>
        )
      ),
    },
    {
      title: "Đưa lên trang chủ",
      dataIndex: "highlight",
      key: "highlight",
      width: 140,
      align: "center",
      render: (highlight, rc) => (
        <Checkbox
          disabled={!canUpdate}
          checked={highlight}
          onChange={(e) => onCheckedHighlight(e, rc?._id)}
        />
      ),
    },
  ];
  // Only SECTION_HIGHLIGHT have isPopup
  if (isSectionHightLight) {
    columns.push({
      title: "Nổi bật",
      dataIndex: "isPopup",
      key: "isPopup",
      width: 70,
      align: "center",
      render: (isPopup, rc) => (
        <Checkbox
          disabled={!canUpdate}
          checked={isPopup}
          onChange={(e) => onCheckedIsPopup(e, rc?._id)}
        />
      ),
    });
  }
  if (canUpdate) {
    columns.push({
      title: "Thao tác",
      dataIndex: "_id",
      key: "_id",
      width: 80,
      align: "center",
      render: (_id) => (
        <Button
          disabled={!canUpdate}
          loading={isSubmitLoading}
          onClick={() => onDelete(_id)}
          danger
          size="small"
        >
          Gỡ
        </Button>
      ),
    });
  }
  useEffect(() => {
    let data: ProductInSection[] = [];
    if (keyword) {
      const filterData = productSection?.filter(
        (item: ProductInSection) =>
          filterSlug(keyword, item?.productId?.name || "") ||
          filterSlug(keyword, item?.productId?.codeBySupplier || "")
      );
      data = filterData;
    } else {
      data = productSection;
    }
    if (filter.highlight !== "all") {
      const filterData = data?.filter(
        (value) => !!value.highlight === !!filter.highlight
      );
      data = filterData;
    }
    if (filter.isPopup !== "all") {
      const filterData = data?.filter(
        (value) => !!value.isPopup === !!filter.isPopup
      );
      data = filterData;
    }
    setDataClone(data);
  }, [keyword, productSection, filter]);

  return (
    <div>
      <Flex
        style={{ position: "sticky", top: 0, zIndex: 2 }}
        gap={10}
        justify={"space-between"}
      >
        <Search
          onChange={(e) => setKeyword(e.target.value)}
          style={{ position: "sticky", top: 0, zIndex: 1 }}
          placeholder="Tìm sản phẩm..."
        />
        <WithPermission permission={POLICIES.UPDATE_SECTIONPRODUCT}>
          <Flex style={{ marginBottom: 8 }} justify={"end"}>
            <BtnAdd onClick={showDrawer} />
          </Flex>
        </WithPermission>
      </Flex>
      <TableAnt
        title={() => (
          <Flex gap={10}>
            <ItemSummary
              label={"Sản phẩm"}
              value={productSection?.length || 0}
            />
            <ItemSummary label={"Đưa lên trang chủ"} value={sumHightLight} />
            {isSectionHightLight && (
              <ItemSummary label={"Nổi bật"} value={sumIsPopup} />
            )}
            <Flex flex={1} gap={5}>
              <div style={{ flex: 1, maxWidth: 200 }}>
                <Badge dot={filter.highlight !== "all"} offset={[5, 1]}>
                  <p>Đưa lên trang chủ</p>
                </Badge>
                <Select
                  onChange={(value) =>
                    setFilter({ ...filter, highlight: value })
                  }
                  value={filter.highlight}
                  style={{ width: "100%" }}
                  options={options}
                />
              </div>
              {isSectionHightLight && (
                <div style={{ flex: 1 }}>
                  <Badge dot={filter.isPopup !== "all"} offset={[5, 1]}>
                    <p>Nổi bật</p>
                  </Badge>
                  <Select
                    onChange={(value) =>
                      setFilter({ ...filter, isPopup: value })
                    }
                    value={filter.isPopup}
                    style={{ width: "100%" }}
                    options={options}
                  />
                </div>
              )}
            </Flex>
          </Flex>
        )}
        rowKey={(rc) => rc?._id}
        columns={columns}
        dataSource={dataClone}
        loading={loading}
        pagination={{
          showTotal(total) {
            return `Tổng cộng ${total}`;
          },
          showSizeChanger: true,
        }}
      />
      <Drawer title="Tìm kiếm sản phẩm" onClose={onClose} open={open}>
        <SectionProductSearch
          productSection={productSection}
          sectionId={sectionId}
        />
      </Drawer>
    </div>
  );
}

function ItemSummary({
  label,
  value,
}: {
  label: string;
  value: number;
}): JSX.Element {
  const formatter_ = (value: any) => <CountUp end={value} separator="," />;

  return (
    <BaseBorderBox styleContent={{ textAlign: "center" }} title={label}>
      <Statistic value={value} precision={2} formatter={formatter_} />
    </BaseBorderBox>
  );
}
