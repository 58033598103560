import { Typography } from "antd";
import React from "react";
import EmptyData from "~/components/Antd/EmptyData";
import { CouponBase } from "../coupon.modal";
import Coupon from "./Coupon";
type propsType = {
  dataSource: CouponBase[];
};
export default function ListCouponInProduct({
  dataSource = [],
}: propsType): React.JSX.Element {
  return (
    <>
      <Typography.Text type="secondary" style={{ textAlign: "center" }}>
        Danh sách mã giảm giá
      </Typography.Text>
      <div style={{ maxHeight: "80vh", overflowY: "scroll", width: "100%" }}>
        {!dataSource?.length && <EmptyData messCheck={false} />}
        {dataSource?.map((item: CouponBase) => (
          <Coupon readOnly key={item._id} coupon={item} />
        ))}
      </div>
    </>
  );
}
