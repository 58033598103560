import { get } from "lodash";
import requester from "~/api/requester";

const apis = {
    getAll: (query?: any) => requester.get(`/api/v1/price-prouct-by-product-id`,query),
    create: (data?: any) => requester.post(`/api/v1/price-prouct`, data),
    update: (data?: any) => requester.put(`/api/v1/price-prouct`, data),
    delete: (id?: any) => requester.delete(`/api/v1/price-prouct/${id}`),
}
export default apis;
