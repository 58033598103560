import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Flex,
  Popconfirm,
  Row,
  Spin, Typography
} from "antd";
import { ColumnsType } from "antd/es/table/InternalTable";
import { get } from "lodash";
import React from "react";
import TableAnt from "~/components/Antd/TableAnt";
import { getDataSourceEachVariant } from "~/modules/productPrice/productPrice.hook";
import { variant } from "~/modules/sale/bill/bill.modal";
import {
  formatPercentage,
  formatter, ObjOrString
} from "~/utils/helpers";
import { cumulativeDiscountValueBase } from "../cumulativeDiscountValue.modal";
type propsType = {
  dataSource: cumulativeDiscountValueBase[];
  onOpen: (dataUpdate?: cumulativeDiscountValueBase) => void;
  loading: boolean;
  onDelete: (p : any) => void;
  loadingDelete: boolean;
  id : string;
  // level : 1 | 2 | 3;
};
export default function CumulativeDiscountValueSaleChannelItem({
  dataSource,
  onOpen,
  loading,
  onDelete,
  loadingDelete,
  id,
  // level,
}: propsType): React.JSX.Element {
  const columns: ColumnsType<cumulativeDiscountValueBase> = [
    {
      title: "Kênh bán hàng",
      dataIndex: "saleChannelId",
      key: "saleChannelId",
      render: (saleChannelId) => ObjOrString(get(saleChannelId, "title", "")),
    },
    {
      title: "Giá trị",
      dataIndex: "value",
      key: "value",
      align: "center",
      width: 150,
      render: (value, rc) => {
        if (rc?.valueType === "VALUE") return formatter(value);
        return formatPercentage(rc?.value) + "%";
      },
    },
    {
      title: "Thao tác",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: 100,
      render: (_id, record) => (
        <Flex justify={"center"} gap={10}>
          <Typography.Link onClick={() => onOpen(record)}>
            Sửa
          </Typography.Link>
          <Popconfirm title="Xác nhận xoá" onConfirm={() => onDelete({idDeleted:_id,cumulativeDiscountId :id})}>
            {loadingDelete ? (
              <Spin spinning />
            ) : (
              <Typography.Link>Xoá</Typography.Link>
            )}
          </Popconfirm>
        </Flex>
      ),
    },
  ];
  return (
    <Row>
      <Col flex={1}>
        <div>
          <Flex className="mb-2" justify={"space-between"}>
            <Typography.Text strong>Giá trị theo kênh</Typography.Text>
            <Button
              onClick={() => onOpen()}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm kênh bán hàng
            </Button>
          </Flex>
          <TableAnt
            rowKey={(rc) => rc?._id}
            columns={columns}
            dataSource={dataSource}
            loading={loading}
          />
        </div>
      </Col>
    </Row>
  );
}
