import { Select, SelectProps, Skeleton } from "antd";
import { DefaultOptionType } from "antd/es/select/index";
import { get } from "lodash";
import React, { useMemo } from "react";
import { filterSelectWithLabel, useFetchState } from "~/utils/helpers";
import apis from "../visitor.api";

interface TypeProps extends SelectProps {
  defaultVisitor?: any[];
  keywordSearchVisitor?: string;
  mergeOption : DefaultOptionType[],
}

export default function SelectVisitor({
  defaultVisitor,
  keywordSearchVisitor,
  disabled = false,
  mergeOption = [],
  ...props
}: Partial<TypeProps>): React.JSX.Element {
  const [visitor, isLoading] = useFetchState({
    api: apis.getAuthenticated,
    useDocs: false,
    shouldRun: !defaultVisitor,
  });
  const options = useMemo(
    () => {
      const options_ = (defaultVisitor ?? visitor)?.map((item: any) => ({
        label: get(item, "fullName", "name"),
        value: get(item, "_id"),
      }));
      return [...mergeOption,...options_]
    },
    [visitor, defaultVisitor,mergeOption]
  );
  return (
    options?.length ?
    <Select
      options={options}
      loading={isLoading}
      allowClear
      style={{ minWidth: 200 }}
      placeholder="Khách hàng vãng lai"
      popupMatchSelectWidth={false}
      filterOption={filterSelectWithLabel}
        showSearch
      disabled = {disabled}
      {...props}
      />
      : <Skeleton.Input active />
  );
}
