/* eslint-disable @typescript-eslint/no-unused-vars */
import { SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { get } from "lodash";
import { useCallback, useMemo, useState } from "react";
import BaseBorderBox from "~/components/common/BaseBorderBox/index";
import ProductGroupSelect from "~/modules/productGroup/components/ProductGroupSelect";
import supplierModule from "~/modules/supplier";
import SelectSupplier from "~/modules/supplier/components/SelectSupplier";
import { useFetchState } from "~/utils/helpers";
import apis from "../saleChannel.api";
import { useGetListProductByIdSaleChannelPaging, useListProductByIdGetSaleChannels, useListProductByIdGetSaleChannelsQueryParam, useSearchProductByIdGetSaleChannelsQueryParam } from "../saleChannel.hook";

import { SaleChannelProductProvider } from "../stores/SaleChannelProductContext";
import SaleChannelControlProduct from "./SaleChannelControlProduct";
import SaleChannelSelectProduct from "./SaleChannelSelectProduct";

type propsType = {
  id?: any;
};
export default function CollaboratorProduct({
  id,

}: propsType): React.JSX.Element {
  const [reFetch, setReFetch] = useState(false);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [keywordList, setKeywordList] = useState("");
  const [supplierSearchId, setSupplierSearchId] = useState();
  const [supplierFilterId, setSupplierFilterId] = useState();
  const [productGroupSearchId, setProductGroupSearchId] = useState();
  const [productGroupFilterId, setProductGroupFilterId] = useState();
  const [paging,setPaging] = useState({
    page : 1,
    limit : 10
  });

  const mutate = useCallback(() => setReFetch(!reFetch), [reFetch]);
  const [suppliers, loadingSupplier] = useFetchState({
    api: supplierModule.api.getAllPublic,
    useDocs: false,
  });

  


  const [querySearch] = useSearchProductByIdGetSaleChannelsQueryParam({
    id,
    keywordSearch,
    supplierSearchId,
    productGroupSearchId,
  })
  const [products, loading, totalDocs] = useFetchState({
    api: apis.searchListProductById,
    query : querySearch,
    reFetch,
  });
  const [queryGetListProduct] = useListProductByIdGetSaleChannelsQueryParam({
    supplierFilterId,
    id,
    keywordList,
    paging,
    productGroupFilterId
  })
const [listProductById,loadingListProductById] = useListProductByIdGetSaleChannels(queryGetListProduct);

  return (
    <SaleChannelProductProvider
    >
    <div className="SelectSupplier m-0" style={{ minHeight: 520 }}>
      <Row
        gutter={16}
        wrap={false}
        style={{ boxSizing: "border-box", marginLeft: 0, marginRight: 0 }}
      >
        <Col span={12}>
          <Row justify={'space-between'} className="">
            <Col span={12}>
            <Typography.Text strong>Nhà cung cấp:</Typography.Text>
            </Col>
            <Col span={12}>
            <SelectSupplier
              style={{width : '100%'}}
              value={supplierSearchId}
              onChange={(value) => setSupplierSearchId(value)}
              defaultSuppliers={suppliers}
            />
            </Col>
          </Row>
          <Row style={{marginTop : 5}} justify={'space-between'} className="">
            <Col span={12}>
            <Typography.Text strong>Nhóm sản phẩm:</Typography.Text>
            </Col>
            <Col span={12}>
              <ProductGroupSelect
                value={productGroupSearchId}
                onChange={(value) => setProductGroupSearchId(value)}
               style={{width : '100%'}}/>
            </Col>
          </Row>
        </Col>
        <Col  span={12}>
        <Row justify={'space-between'} className="">
            <Col span={12}>
            <Typography.Text strong>Nhà cung cấp:</Typography.Text>
            </Col>
            <Col span={12}>
            <SelectSupplier
              style={{width : '100%'}}
              value={supplierFilterId}
              onChange={(value) => setSupplierFilterId(value)}
              defaultSuppliers={suppliers}
            />
            </Col>
          </Row>
          <Row style={{marginTop : 5}} justify={'space-between'} className="">
            <Col span={12}>
            <Typography.Text strong>Nhóm sản phẩm:</Typography.Text>
            </Col>
            <Col span={12}>
              <ProductGroupSelect
                value={productGroupFilterId}
                onChange={(value) => setProductGroupFilterId(value)}
               style={{width : '100%'}}/>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        gutter={16}
        wrap={false}
        style={{ boxSizing: "border-box", marginLeft: 0, marginRight: 0 }}
      >
        <Col span={12}>
          <BaseBorderBox
            styleContent={{ paddingLeft: 0, paddingRight: 0 }}
            title={
              <span>
                Sản phẩm chưa chọn{" "}
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={mutate}
                  icon={<SyncOutlined />}
                />
              </span>
            }
          >
            <div className="ServiceGroup">
              <SaleChannelSelectProduct
                mutate={mutate}
                id={id}
                dataSource={products}
                setKeyword={setKeywordSearch}
                loading={loading}
                totalDocs={totalDocs}
              />
            </div>
          </BaseBorderBox>
        </Col>
        <Col style={{ marginTop: 2 }} span={12}>
          <BaseBorderBox
            styleContent={{ paddingLeft: 0, paddingRight: 0, marginTop: 3 }}
            title={"Sản phẩm đã chọn"}
          >
            <SaleChannelControlProduct
              dataSource={listProductById}
              isLoading={loadingListProductById}
              id={id}
              setKeyword={setKeywordList}
              setPaging={setPaging}
            />
          </BaseBorderBox>
        </Col>
      </Row>
    </div>
    </SaleChannelProductProvider>
  );
}
