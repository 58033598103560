import { DefaultOptionType } from "antd/es/select/index";

export const SUGGEST : DefaultOptionType[] = [
    {
        label : "Danh mục sản phẩm",
        value : "/san-pham-tong-hop"
    },
    {
        label : "Giới thiệu",
        value : "/gioi-thieu"
    },
    {
        label : "Liên hệ",
        value : "/lien-he"
    },
    {
        label : "Tuyển dụng",
        value : "/tuyen-dung"
    },
    {
        label : "Cẩm nang",
        value : "/tin-tuc"
    },
    {
        label : "Chính sách",
        value : "/chinh-sach"
    },
]