import { Alert, Divider, Flex } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import ModalAnt from "~/components/Antd/ModalAnt";
import {
    useDeleteProductPrice,
    useGetProductPrices
} from "~/modules/productPrice/productPrice.hook";
import { ProductPriceResponse } from "~/modules/productPrice/productPrice.modal";
import { variant } from "~/modules/sale/bill/bill.modal";
import PriceSaleChannelForm from "./PriceSaleChannelForm";
import PriceSaleChannelItem from "./PriceSaleChannelItem";
type propsType = {
  variants?: variant[];
  productId?: any;
  level : 1 | 2 | 3; // 3 is have Variants And Product, 2 is have Product , 1 Not Implemented
};
export default function PriceSaleChannel({
  variants,
  productId,
  level,
}: propsType): React.JSX.Element {
  const query = useMemo(() => productId ?({ productId }) : null, [productId]);
  const [isSubmitLoading, onDelete] = useDeleteProductPrice();
  const [data, loading] = useGetProductPrices(query);
  const [open, setOpen] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<ProductPriceResponse | null>();
  const [variantId, setVariantId] = useState<any>();
  const onOpen = useCallback(
    (variantId_: any, dataUpdate_?: ProductPriceResponse) => {
      dataUpdate_ && setDataUpdate(dataUpdate_);
      setVariantId(variantId_);
      setOpen(true);
    },
    []
  );
  const onClose = useCallback(() => {
    setDataUpdate(null);
    setVariantId(null);
    setOpen(false);
  }, []);

  return (
    <Flex vertical gap={10}>
      {level === 3 && <Alert message="Chỉ có đơn vị đã được lưu mới được thêm giá theo kênh" type="info" showIcon />}
      {level === 2 && <Alert message="Giá theo kênh áp dụng cho toàn đơn vị (Nếu đơn vị đã được thiếp lập sẽ ưu tiên lấy giá của đơn vị)" type="info" showIcon />}
      {level === 3 && variants?.map((variant: variant) => (
        <>
          <PriceSaleChannelItem
            onDelete={onDelete}
            loadingDelete={isSubmitLoading}
            loading={loading}
            dataPriceSaleChannel={data}
            onOpen={onOpen}
            variant={variant}
            key={variant._id}
            level={level}
          />
          <Divider />
        </>
      ))}
      {
        level === 2 && 
        <PriceSaleChannelItem
        onDelete={onDelete}
        loadingDelete={isSubmitLoading}
        loading={loading}
        dataPriceSaleChannel={data}
        onOpen={onOpen}
        level={level}
      />
      }
      <ModalAnt
        destroyOnClose
        title={dataUpdate ? "Cập nhật " : "Tạo mới " + "Giá theo kênh"}
        footer={null}
        centered
        open={open}
        onCancel={onClose}
      >
        <PriceSaleChannelForm
          variantId={variantId}
          productId={productId}
          dataUpdate={dataUpdate}
          onCancel={onClose}
          dataPriceSaleChannel={data}
          level={level}
        />
      </ModalAnt>
    </Flex>
  );
}
