import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Image, Popconfirm, Rate, Switch, Typography } from "antd";
import { ColumnsType } from "antd/lib/table/InternalTable";
import React from "react";
import { Link } from "react-router-dom";
import SearchAnt from "~/components/Antd/SearchAnt";
import TableAnt from "~/components/Antd/TableAnt";
import Breadcrumb from "~/components/common/Breadcrumb";
import HtmlShow from "~/components/common/HtmlShow";
import WithPermission from "~/components/common/WithPermission";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import { ObjOrString } from "~/utils/helpers";
import {
    useDeleteRating,
    useGetRatings, useRatingQueryParams, useUpdateRating, useUpdateRatingParams
} from "../rating.hook";
type propsType = {};
export default function Rating(props: propsType): React.JSX.Element {
  const [query] = useRatingQueryParams();
  const [keyword, { setKeyword, onParamChange }] = useUpdateRatingParams(query);
  const [dataSource, isLoading] = useGetRatings(query);
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_RATING);
  const [isSubmitLoading, onDelete] = useDeleteRating();
  const [, onUpdate] = useUpdateRating();

  const columns: ColumnsType = [
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      render : (value) => ObjOrString(value)
    },
    {
      title: "Mô tả ngắn",
      dataIndex: "subTitle",
      key: "subTitle",
      render : (value) => ObjOrString(value)
    },
    {
      title: "Điểm đánh giá",
      dataIndex: "rate",
      key: "rate",
      width : 120,
      align : 'center',
      render : (rate) => <Rate value={rate}/>
    },
    {
      title: "Hiển thị",
      dataIndex: "status",
      key: "status",
      width : 120,
      align : 'center',
      render : (status,rc) => <Switch disabled={!canUpdate} checked={status === "ACTIVE"} onChange={(checked) => onUpdate({_id : rc?._id,status : checked ? "ACTIVE" : "INACTIVE"})}/>
    },
    {
      title: "Thao tác",
      key: "action",
      dataIndex: "_id",
      width: 120,
      align : 'center',
      render: (_id) => (
        <Flex justify={'center'} align={"center"} gap={5}>
          <WithPermission permission={POLICIES.UPDATE_RATING}>
          <Typography.Link href={`/rating-update/${_id}`}>Sửa</Typography.Link>
            </WithPermission>|
          <WithPermission permission={POLICIES.DELETE_RATING}>
          <Popconfirm
            okButtonProps={{ loading: isSubmitLoading }}
            title="Xác nhận xoá"
            onConfirm={() => onDelete(_id)}
          >
            <Typography.Link type="danger">Xoá</Typography.Link>
          </Popconfirm>
          </WithPermission>
        </Flex>
      ),
    },
  
  ];
  return (
    <div>
      <Breadcrumb title={"Danh sách đánh giá"} />
      <Flex style={{ marginBottom: 8 }} justify={"space-between"}>
        <SearchAnt onParamChange={onParamChange} />
        <WithPermission permission={POLICIES.WRITE_RATING}>
        <Link to={'/rating-create'}>
        <Button type="primary" icon={<PlusOutlined />}>
          Thêm mới
        </Button>
        </Link>
        </WithPermission>
      </Flex>
      <TableAnt
        rowKey={rc => rc?._id}
        columns={columns}
        dataSource={dataSource}
        stickyTop
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `Tổng cộng: ${total} `,
        }}
        loading={isLoading}
      />
    </div>
  );
}
