import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../cumulativeDiscountValue.api'; 
import { cumulativeDiscountValueActions } from './reducer';

function* getListCumulativeDiscountValue({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(cumulativeDiscountValueActions.getListSuccess(data));
    
  } catch (error:any) {
    yield put(cumulativeDiscountValueActions.getListFailed(error));
  }
}

// function* getByIdCumulativeDiscountValue({payload:id} : any) : any {
//   try {
//     const data = yield call(api.getById,id);
//     yield put(cumulativeDiscountValueActions.getByIdSuccess(data));
//   } catch (error:any) {
//     yield put(cumulativeDiscountValueActions.getByIdFailed(error));
//   }
// }

function* createCumulativeDiscountValue({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(cumulativeDiscountValueActions.createSuccess(data));
    yield delay(300);
    yield put(cumulativeDiscountValueActions.clearAction());
  } catch (error:any) {
    yield put(cumulativeDiscountValueActions.createFailed(error));
  }
}

function* updateCumulativeDiscountValue({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(cumulativeDiscountValueActions.updateSuccess(data));
    yield delay(300);
    yield put(cumulativeDiscountValueActions.clearAction());
  } catch (error:any) {
    yield put(cumulativeDiscountValueActions.updateFailed(error));
  }
}
function* deleteCumulativeDiscountValue({payload} : any) : any {
  try {
    const data = yield call(api.delete,payload);
    yield put(cumulativeDiscountValueActions.deleteSuccess(data));
    yield delay(300);
    yield put(cumulativeDiscountValueActions.clearAction());
  } catch (error:any) {
    yield put(cumulativeDiscountValueActions.deleteFailed(error));
  }
}


export default function* cumulativeDiscountValueSaga() {
  yield takeLatest(cumulativeDiscountValueActions.getListRequest, getListCumulativeDiscountValue);
  // yield takeLatest(cumulativeDiscountValueActions.getByIdRequest, getByIdCumulativeDiscountValue);
  yield takeLatest(cumulativeDiscountValueActions.createRequest, createCumulativeDiscountValue);
  yield takeLatest(cumulativeDiscountValueActions.updateRequest, updateCumulativeDiscountValue);
  yield takeLatest(cumulativeDiscountValueActions.deleteRequest, deleteCumulativeDiscountValue);
}
