import ProductPriceScreen from "./screens/ProductPrice";
import productPriceApi from "./productPrice.api";
import * as productPriceHook from "./productPrice.hook";
import * as productPriceService from "./productPrice.service";
import * as productPriceModels from "./productPrice.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : ProductPriceScreen,
    },
    api : productPriceApi,
    hook : productPriceHook,
    service : productPriceService,
    model : productPriceModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;