import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { useFetchState } from "~/utils/helpers";
import { useGetRole } from "../auth/auth.hook";
import {
  useAddProductPharmacy,
  useAddProductQuery,
  useGetPharmacyId,
  useProductsSearch,
  useRemoveProductPharmacy,
} from "./pharmacy.hook";
import { useMatchPolicy } from "../policy/policy.hook";
import POLICIES from "../policy/policy.auth";
export type GlobalPharmacyProduct = {
  canAdd: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  useAdd: () => void;
  useRemove: () => void;
  useGetTarget: (param?: any) => void;
};
const PharmacyProduct = createContext<GlobalPharmacyProduct>({
  canAdd: false,
  canUpdate: false,
  canDelete: false,
  useAdd: () => {},
  useRemove: () => {},
  useGetTarget: (param?: any) => { },
});

type PharmacyProductProviderProps = {
  children: ReactNode;
  id: string;
};

export function PharmacyProductProvider({
  children,
  id,
}: PharmacyProductProviderProps): JSX.Element {
  const canAdd = useMatchPolicy(POLICIES.WRITE_PHARMAPROFILE);
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_PHARMAPROFILE);
  const canDelete = useMatchPolicy(POLICIES.DELETE_PHARMAPROFILE);
  return (
    <PharmacyProduct.Provider
      value={{
        canAdd,
        canUpdate,
        canDelete,
        useAdd: useAddProductPharmacy,
        useRemove: useRemoveProductPharmacy,
        useGetTarget: useGetPharmacyId,
      }}
    >
      {children}
    </PharmacyProduct.Provider>
  );
}

const usePharmacyProductStore = (): GlobalPharmacyProduct =>
  useContext(PharmacyProduct);

export default usePharmacyProductStore;
