import { get } from "lodash";
import requester from "~/api/requester";

const apis = {
    getAll: (query?: any) => requester.get(`/api/v1/rating`, query),
    getById: (id?: any) => requester.get(`/api/v1/rating/${id}?raw=true`),
    create: (data?: any) => requester.post(`/api/v1/rating`, data),
    update: (data?: any) => requester.put(`/api/v1/rating/${get(data,'_id')}`, data),
    delete: (id?: any) => requester.delete(`/api/v1/rating/${id}`),
}
export default apis;
