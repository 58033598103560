import { Select } from 'antd';
import { SelectProps } from 'antd/lib/index';
import React, { useMemo } from 'react';
import { useFetchState } from '~/utils/hook';
import apis from '../saleChannel.api';
interface PropsType extends SelectProps{
    valuesNeedDisabled? : string[];
}
export default function SaleChannelSelect({valuesNeedDisabled,...props}:PropsType) : React.JSX.Element {
    const [options,loading] = useFetchState({api : apis.getOptions,useDocs : false});
    const mapDisabled = useMemo(() => options?.map((opt : any) => ({
        ...opt,
        disabled : valuesNeedDisabled?.includes(opt?.value),
    })),[valuesNeedDisabled,options]);
    return (
        <Select options={mapDisabled} loading={loading} {...props}/>
    )
}