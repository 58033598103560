import { List } from "antd";
import React from "react";
type propsType = {
  data: {
    title: string;
    _id: string;
  }[];
};
export default function SaleChannelList({
  data,
}: propsType): React.JSX.Element {
  return (
    <List
      style={{ maxHeight: 300, overflowY: "scroll" }}
      bordered
      dataSource={data}
      renderItem={(item) => <List.Item>{item?.title}</List.Item>}
    />
  );
}
