import { get } from "lodash";
import requester from "~/api/requester";

const apis = {
    getAll: ({cumulativeDiscountId} : any) => requester.get(`/api/v1/overwrite-discount/${cumulativeDiscountId}`),
    // getById: (id?: any) => requester.get(`/api/v1/cumulativeDiscountValue/${id}`),
    create: ({cumulativeDiscountId,...restData}: any) => requester.post(`/api/v1/overwrite-discount/${cumulativeDiscountId}`, restData),
    update: ({cumulativeDiscountId,...restData}: any) => requester.put(`/api/v1/overwrite-discount/${cumulativeDiscountId}`, restData),
    delete: ({cumulativeDiscountId,idDeleted}: any) => requester.delete(`/api/v1/overwrite-discount`,{cumulativeDiscountId,idDeleted}),
}
export default apis;
