import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { InstanceModuleRedux } from "~/redux/instanceModuleRedux";
import { initStateSlice } from "~/redux/models";
import { getPaging } from "~/utils/helpers";
interface cloneInitState extends initStateSlice {
  listProductById?: any;
  isLoadingListProductById?: boolean;
  pagingListProductById?: any;
  getListProductByIdFailed?: any;
  isSubmitLoadingListProductById?: any;
  addListProductByIdFailed?: any;
  addListProductByIdSuccess?: any;
  removeListProductByIdFailed?: any;
  removeListProductByIdSuccess?: any;
  updateListProductByIdFailed?: any;
  updateListProductByIdSuccess?: any;
  // Add cloneInitState Type Here
}
class SaleChannelClassExtend extends InstanceModuleRedux {
  cloneReducer;
  cloneInitState: cloneInitState;
  constructor() {
    super("saleChannel");
    this.cloneReducer = {
      ...this.initReducer,
      getListProductByIdRequest: (state: cloneInitState) => {
        state.isLoadingListProductById = true;
        state.getListFailed = null;
      },
      getListProductByIdSuccess: (state: cloneInitState, { payload }: any) => {
        state.isLoadingListProductById = false;
        state.listProductById = get(payload, "docs", []);
        state.pagingListProductById = getPaging(payload);
      },
      getListProductByIdFailed: (
        state: cloneInitState,
        { payload }: { payload: any }
      ) => {
        state.isLoadingListProductById = false;
        state.getListProductByIdFailed = payload;
      },

    // add
    addListProductByIdRequest: (state:cloneInitState) => {
      state.isSubmitLoadingListProductById = true;
      state.addListProductByIdFailed = null;
    },
    addListProductByIdSuccess: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoadingListProductById = false;
      state.addListProductByIdSuccess = payload;
    },
    addListProductByIdFailed: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoadingListProductById = false;
      state.addListProductByIdFailed = payload;
    },
    // remove
    removeListProductByIdRequest: (state:cloneInitState) => {
      state.isSubmitLoadingListProductById = true;
      state.removeListProductByIdFailed = null;
    },
    removeListProductByIdSuccess: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoadingListProductById = false;
      state.removeListProductByIdSuccess = payload;
    },
    removeListProductByIdFailed: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoadingListProductById = false;
      state.removeListProductByIdFailed = payload;
    },
    // update
    updateListProductByIdRequest: (state:cloneInitState) => {
      state.isSubmitLoadingListProductById = true;
      state.updateListProductByIdFailed = null;
    },
    updateListProductByIdSuccess: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoadingListProductById = false;
      state.updateListProductByIdSuccess = payload;
      const data = get(payload,'data');
      state.listProductById = [...state.listProductById]?.map((item) => data?._id === item?._id ? {...item , status : data?.status} : item);
    },
    updateListProductByIdFailed: (state:cloneInitState, { payload }:{payload:any}) => {
      state.isSubmitLoadingListProductById = false;
      state.updateListProductByIdFailed = payload;
    },

      // Want Add more reducer Here...
    };
    this.cloneInitState = {
      ...this.initialState,
      listProductById: [],
      isLoadingListProductById: false,
      pagingListProductById: null,
      getListProductByIdFailed: null,
      isSubmitLoadingListProductById: null,
      addListProductByIdSuccess: null,
      addListProductByIdFailed: null,
      removeListProductByIdSuccess: null,
      removeListProductByIdFailed: null,
      // Want Add more State Here...
    };
  }
  createSlice() {
    return createSlice({
      name: this.module,
      initialState: this.cloneInitState,
      reducers: this.cloneReducer,
    });
  }
}

const newSlice = new SaleChannelClassExtend();
const data = newSlice.createSlice();

export const saleChannelSliceAction = data.actions;
export default data.reducer;
