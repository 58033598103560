import { put, call, takeLatest, delay } from 'redux-saga/effects';
import api from '../productPrice.api'; 
import { productPriceActions } from './reducer';

function* getListProductPrice({payload:query} : any) : any {
  try {
    const data = yield call(api.getAll,query);
    yield put(productPriceActions.getListSuccess(data));
    yield put(productPriceActions.clearAction());
  } catch (error:any) {
    yield put(productPriceActions.getListFailed(error));
  }
}

// function* getByIdProductPrice({payload:id} : any) : any {
//   try {
//     const data = yield call(api.getById,id);
//     yield put(productPriceActions.getByIdSuccess(data));
//   } catch (error:any) {
//     yield put(productPriceActions.getByIdFailed(error));
//   }
// }

function* createProductPrice({payload} : any) : any {
  try {
    const data = yield call(api.create,payload);
    yield put(productPriceActions.createSuccess(data));
    yield delay(300);
    yield put(productPriceActions.resetAction());
  } catch (error:any) {
    yield put(productPriceActions.createFailed(error));
  }
}

function* updateProductPrice({payload} : any) : any {
  try {
    const data = yield call(api.update,payload);
    yield put(productPriceActions.updateSuccess(data));
    yield delay(300);
    yield put(productPriceActions.resetAction());
  } catch (error:any) {
    yield put(productPriceActions.updateFailed(error));
  }
}
function* deleteProductPrice({payload : id} : any) : any {
  try {
    const data = yield call(api.delete,id);
    yield put(productPriceActions.deleteSuccess(data));
    yield delay(300);
    yield put(productPriceActions.resetAction());
  } catch (error:any) {
    yield put(productPriceActions.deleteFailed(error));
  }
}


export default function* productPriceSaga() {
  yield takeLatest(productPriceActions.getListRequest, getListProductPrice);
  // yield takeLatest(productPriceActions.getByIdRequest, getByIdProductPrice);
  yield takeLatest(productPriceActions.createRequest, createProductPrice);
  yield takeLatest(productPriceActions.updateRequest, updateProductPrice);
  yield takeLatest(productPriceActions.deleteRequest, deleteProductPrice);
}
