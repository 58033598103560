import { Button, Form, Radio } from "antd";
import React, { useEffect, useMemo } from "react";
import InputNumberAnt from "~/components/Antd/InputNumberAnt";
import DebounceSelectMultipleItemSaleChannel from "~/components/common/DebounceSelectMultiple/DebounceSelectMultipleItemSaleChannel";
import { DebounceSelectMultipleProvider } from "~/components/common/DebounceSelectMultiple/DebounceSelectMultipleProvider";
import { requireRules } from "~/constants/defaultValue";
import {
  getDataSourceEachVariant,
  useCreateProductPrice,
  useUpdateProductPrice,
} from "~/modules/productPrice/productPrice.hook";
import { ProductPriceResponse } from "~/modules/productPrice/productPrice.modal";
type propsType = {
  variantId: string;
  productId: string;
  onCancel: () => void;
  dataUpdate?: ProductPriceResponse | null;
  dataPriceSaleChannel : ProductPriceResponse[];
  level : 1 | 2 | 3; // 3 is have Variants And Product, 2 is have Product , 1 Not Implemented

};
export default function PriceSaleChannelForm({
  variantId,
  productId,
  onCancel,
  dataUpdate,
  dataPriceSaleChannel,
  level,
}: propsType): React.JSX.Element {
  const [isSubmitLoading, onCreate] = useCreateProductPrice(onCancel);
  const [, onUpdate] = useUpdateProductPrice(onCancel);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    const submitData = {
      productId,
      ...values,
    };
    if(level === 3){
      submitData.variantId = variantId;
    }
    
    if (!!dataUpdate) {
      onUpdate(submitData);
    } else {
      onCreate(submitData);
    }
  };
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
        saleChannelId: dataUpdate?.saleChannelId?._id,
      });
    } else {
      form.resetFields();
    }
  }, [dataUpdate]);

  // Get Values Selected To Disabled Selected in This Variant
  const dataSourceInThisVariant = useMemo(() => getDataSourceEachVariant(
    variantId,
    dataPriceSaleChannel,
    level,
  ),[variantId,dataPriceSaleChannel]);
  const valuesSaleChannel = useMemo(() => dataSourceInThisVariant?.map((item) => item?.saleChannelId?._id),[dataSourceInThisVariant]);
  
  return (
    <DebounceSelectMultipleProvider valuesSaleChannel={valuesSaleChannel} useSaleChannel>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        labelAlign="left"
        initialValues={{
          valueType: "VALUE",
        }}
      >
        <Form.Item name={"saleChannelId"} label="Kênh bán hàng" rules={requireRules}>
          <DebounceSelectMultipleItemSaleChannel />
        </Form.Item>
        <Form.Item name={"value"} label="Giá trị" rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue(['valueType']) === 'VALUE') {
                if(value < 0){
                  return Promise.reject(new Error('Vui lòng nhập giá trị >= 0'));
                }
              }
              if (getFieldValue(['valueType']) === 'PERCENT') {
                if(value < -100){
                  return Promise.reject(new Error('Không được giảm quá âm 100%'));
                }
              
              }
              return Promise.resolve();
            
            }
          }),
          ...requireRules
        ]}>
          <InputNumberAnt />
        </Form.Item>
        <Form.Item name={"valueType"} label="Kiểu giá trị" rules={requireRules}>
          <Radio.Group size="small" buttonStyle="solid">
            <Radio.Button value="VALUE">Giá trị</Radio.Button>
            <Radio.Button value="PERCENT">%</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Button
          block
          loading={isSubmitLoading}
          type="primary"
          onClick={() => form.submit()}
        >
          {!!dataUpdate ? "Cập nhật" : "Tạo mới"}
        </Button>
      </Form>
    </DebounceSelectMultipleProvider>
  );
}
