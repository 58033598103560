import RatingScreen from "./screens/Rating";
import ratingApi from "./rating.api";
import * as ratingHook from "./rating.hook";
import * as ratingService from "./rating.service";
import * as ratingModels from "./rating.modal";
import reducer from "./redux/reducer";
import saga from "./redux/saga";
import components from "./components";

const moduleExport = {
    page : {
        index : RatingScreen,
    },
    api : ratingApi,
    hook : ratingHook,
    service : ratingService,
    model : ratingModels,
    redux : {reducer,saga},
    components,
};
export default moduleExport;