import { SmallDashOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex, Switch, Typography } from "antd";
import { ColumnsType } from "antd/es/table/InternalTable";
import Search from "antd/lib/input/Search";
import { debounce, get } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import TableAnt from "~/components/Antd/TableAnt";
import { useGetListProductByIdSaleChannelPaging, useRemoveListProductByIdSaleChannel, useUpdateListProductByIdSaleChannel } from "../saleChannel.hook";
import useSaleChannelProductStore from "../stores/SaleChannelProductContext";
type propsType = {
  id?: any;
  isLoading?: boolean;
  dataSource?: any[];
  setKeyword: (keyword: string) => void;
  setPaging: (p?: any) => void;
};

export default function SaleChannelControlProduct({
  id,
  dataSource,
  isLoading,
  setKeyword,
  setPaging,
}: propsType): React.JSX.Element {
  const {canDelete} = useSaleChannelProductStore()
  const paging = useGetListProductByIdSaleChannelPaging();
  
  const [data, setData] = useState<any>([]);
  const [isSubmitLoading, removeProduct]: any =
    useRemoveListProductByIdSaleChannel();
  const [, updateProduct]: any =
    useUpdateListProductByIdSaleChannel();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (keyword: any) => {
    setKeyword(keyword);
  };
  const debounceSearch = debounce(onSearch, 300);
  const onRemoveMultiProduct = useCallback(() => {
    const submitData: any = {
      id,
      productIds: selectedRowKeys?.map((rowKey: any) => rowKey),
    };
    removeProduct(submitData);
  }, [selectedRowKeys]);
  const onRemoveProduct = useCallback(
    (productId: string) => {
      const submitData: any = {
        id,
        productIds: [productId],
      };
      removeProduct(submitData);
    },
    [id]
  );

  const onChangeStatus = useCallback(
    (productId: string,status : "ACTIVE" | "INACTIVE" ) => {
      const submitData: any = {
        id,
        productId,
        status
      };
      
      updateProduct(submitData);
    },
    [id]
  );

  const columns: ColumnsType = useMemo(
    () => [
      {
        title: "Tên sản phẩm",
        dataIndex: "product",
        key: "product",
        render(product, record, index) {
          return (
            <Typography.Text strong>
              {get(product, "codeBySupplier", "")} - {get(product, "name", "")}
            </Typography.Text>
          );
        },
      },
      {
        title: "Trạng thái",
        dataIndex: "productId",
        key: "productId",
        width : 90,
        align : 'center',
        render(productId, rc, index) {
          return (
          <Switch checked={rc?.status === "ACTIVE"} onChange={(checked) => onChangeStatus(productId,checked ? "ACTIVE" : "INACTIVE")} size="small"/>
          );
        },
      },
      {
        title: selectedRowKeys?.length ? (
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  label: "Gỡ",
                  key: "2",
                  danger: true,
                  disabled : !canDelete,
                  onClick: () => onRemoveMultiProduct(),
                },
              ],
            }}
          >
            <Button type="primary" icon={<SmallDashOutlined />} />
          </Dropdown>
        ) : (
          ""
        ),
        dataIndex: "productId",
        key: "productId",
        align: "center",
        width: 70,
        render(productId, record: any, index) {
          return !selectedRowKeys?.includes(productId) ? (
            <Button
              disabled={!canDelete}
              size="small"
              loading={isSubmitLoading}
              onClick={() => onRemoveProduct(productId)}
              danger
            >
              Gỡ
            </Button>
          ) : (
            <></>
          );
        },
      },
    ],
    [id, isSubmitLoading, selectedRowKeys]
  );
  useEffect(() => {
    setSelectedRowKeys([]);
    setData([]);
  }, [dataSource]);
  return (
    <>
      <TableAnt
        title={() => (
          <Search allowClear onChange={(e) => debounceSearch(e.target.value)} />
        )}
        loading={isLoading}
        dataSource={data?.length ? data : dataSource}
        columns={columns}
        pagination={{
          ...paging,
          showSizeChanger : true,
          onChange(page, pageSize) {
            setPaging({page,limit : pageSize})
          },
        }}
        size="small"
        scroll={{ y: 400 }}
        rowSelection={rowSelection}
        rowKey={(rc) => rc?.productId}
        footer={() => (
          <Flex justify={"end"}>
            <Typography.Text style={{ fontStyle: "italic" }} strong>
              Tổng sản phẩm:{" "}
              {paging?.total}
            </Typography.Text>
          </Flex>
        )}
      />
    </>
  );
}
