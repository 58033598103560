/* eslint-disable @typescript-eslint/no-unused-vars */
import { SyncOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { get } from "lodash";
import { useCallback, useMemo, useState } from "react";
import BaseBorderBox from "~/components/common/BaseBorderBox/index";
import SelectSupplier from "~/modules/supplier/components/SelectSupplier";
import { useFetchState } from "~/utils/helpers";

import usePharmacyProductStore, { PharmacyProductProvider } from "./PharmaAssignProductProvider";
import apis from "../pharmacy/pharmacy.api";
import supplierModule from "~/modules/supplier";
import { useAddProductQuery, useAssignedProductQuery, useProductsAssigned, useProductsAssignedPaging, useProductsSearch, useProductsSearchPaging } from "./pharmacy.hook";
import SelectProduct from "./component/SelectProduct";
import ControlProduct from "./component/ControlProduct";
export type ConfigType = {
  discount? : {
    discountType: "PERCENT" | "VALUE",
    value: number,
  },
}
const defaultConfig = {
  discount: {
    discountType: "PERCENT",
    value: 10,
  },
} as ConfigType
type propsType = {
  id?: any;
  useGetUser: any;
  apiSearchProduct: any;
  config? : ConfigType
};
export default function PharmacyAssignProduct({
  id,
  useGetUser,
  apiSearchProduct,
  config = defaultConfig,
}: propsType): React.JSX.Element {
  const [reFetch, setReFetch] = useState(false);
  const [reFetchAssigned, setReFetchAssigned] = useState(false);
  const [supplierSelectId, setSupplierSelectId] = useState<string| null | undefined>(null);
  const [supplierFilterId, setSupplierFilterId] = useState<string| null | undefined>(null);
     
  const [query, onTableChange,setKeyword] = useAddProductQuery(id, supplierSelectId);
  const [products, isLoadingSearch] = useProductsSearch(query);
  const pagingSearch = useProductsSearchPaging();
  const [queryAssigned, onTableChangeAssigned,setKeywordAssigned] = useAssignedProductQuery(id, supplierFilterId);
  const [assigned, isLoadingAssigned] = useProductsAssigned(queryAssigned);
  const  pagingAssigned = useProductsAssignedPaging();
  const [pharmacy, isLoading]: any = useGetUser(id);
  const mutate = useCallback(() => {
    setReFetch(!reFetch);
    setReFetchAssigned(!reFetchAssigned);
  }, [reFetch,reFetchAssigned]);

  const productsSearch = useMemo(
    () =>
      products?.filter(
        (product: any) =>
          !get(pharmacy, "products", [])?.some(
            (i: any) => get(i, "productId") === get(product, "_id")
          )
      ),
    [products, pharmacy]
  );

  const [suppliers, loadingSupplier] = useFetchState({
    api: supplierModule.api.getAllPublic,
    useDocs: false,
  });

  return (
    <PharmacyProductProvider id={id}>
    <div className="SelectSupplier m-0" style={{ minHeight: 520 }}>
      <Row
        gutter={16}
        wrap={false}
        style={{ boxSizing: "border-box", marginLeft: 0, marginRight: 0 }}
      >
        <Col span={12}>
          <div className="d-flex align-items-center ml-1 gap-2">
            <Typography.Text strong>Chọn nhà cung cấp:</Typography.Text>
            <SelectSupplier
              value={supplierSelectId}
              onChange={(value) => setSupplierSelectId(value)}
              defaultSuppliers={suppliers}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="d-flex align-items-center ml-1 gap-2">
            <Typography.Text strong>Chọn nhà cung cấp:</Typography.Text>
            <SelectSupplier
              value={supplierFilterId}
              onChange={(value) => setSupplierFilterId(value)}
              defaultSuppliers={suppliers}
            />
          </div>
        </Col>
      </Row>

      <Row
        gutter={16}
        wrap={false}
        style={{ boxSizing: "border-box", marginLeft: 0, marginRight: 0 }}
      >
        <Col span={12}>
          <BaseBorderBox
            styleContent={{ paddingLeft: 0, paddingRight: 0 }}
            title={
              <span>
                Sản phẩm chưa chọn{" "}
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={mutate}
                  icon={<SyncOutlined />}
                />
              </span>
            }
          >
            <div className="ServiceGroup">
              <SelectProduct
                mutate={mutate}
                id={id}
                dataSource={productsSearch || []}
                setKeyword={setKeyword}
                loading={isLoadingSearch}
                  config={config}
                  paging={pagingSearch}
                  onTableChange = {onTableChange}
              />
            </div>
          </BaseBorderBox>
        </Col>
        <Col style={{ marginTop: 2 }} span={12}>
          <BaseBorderBox
            styleContent={{ paddingLeft: 0, paddingRight: 0, marginTop: 3 }}
            title={"Sản phẩm đã chọn"}
          >
            <ControlProduct
                dataSource={assigned || []}
                isLoading={isLoadingAssigned}
                id={id}
                mutate={mutate}
                paging={pagingAssigned}
                onTableChange={onTableChangeAssigned}
                setKeyword={setKeywordAssigned}
            />
          </BaseBorderBox>
        </Col>
      </Row>
    </div>
    </PharmacyProductProvider>
  );
}
