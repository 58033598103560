import {
    createContext,
    ReactNode, useContext
} from "react";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
  export type GlobalSaleChannelProduct = {
    canUpdate : any,
    canCreate : any,
    canDelete : any,
  };
  const SaleChannelProduct = createContext<GlobalSaleChannelProduct>({
    canUpdate : false,
    canCreate : false,
    canDelete : false,
  });
  
  type SaleChannelProductProviderProps = {
    children: ReactNode;
  };

  
  export function SaleChannelProductProvider({
    children,

  }: SaleChannelProductProviderProps): JSX.Element {
    const canUpdate = useMatchPolicy(POLICIES.UPDATE_SALESCHANNEL);
    const canCreate = useMatchPolicy(POLICIES.WRITE_SALESCHANNEL);
    const canDelete = useMatchPolicy(POLICIES.DELETE_SALESCHANNEL);
    return (
      <SaleChannelProduct.Provider
        value={{
          canUpdate,
          canCreate,
          canDelete,
        }}
      >
        {children}
      </SaleChannelProduct.Provider>
    );
  }
  
  const useSaleChannelProductStore = (): GlobalSaleChannelProduct => useContext(SaleChannelProduct);
  
  export default useSaleChannelProductStore;
  