import { DownOutlined, MinusOutlined, SmallDashOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex, Modal, Typography } from "antd";
import { ColumnsType } from "antd/es/table/InternalTable";
import Search from "antd/lib/input/Search";
import { debounce, get, pick } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { filterSlug } from "~/utils/helpers";
import TableAnt from "~/components/Antd/TableAnt";
import ModalAnt from "~/components/Antd/ModalAnt";
import usePharmacyProductStore from "../PharmaAssignProductProvider";
import { SubmitDataAssignProduct } from "../pharmacy.modal";
type propsType = {
  id?: any;
  isLoading?: boolean;
  dataSource?: any[];
  mutate?: () => void;
  paging?: any;
  onTableChange?: any;
  setKeyword: (kw:any) => void
};

export default function ControlProduct({
  id,
  dataSource,
  isLoading,
  mutate,
  paging,
  onTableChange,
  setKeyword
}: propsType): React.JSX.Element {
  const {canDelete,canUpdate, useRemove} = usePharmacyProductStore();
  const [data,setData] = useState<any>([]);
  const [isSubmitLoading, removeProduct]: any = useRemove();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSearch = (keyword:any) => {
    const filterResult = dataSource?.filter((item:any) => filterSlug(keyword,get(item,'product.name')) || filterSlug(keyword,get(item,'product.codeBySupplier')));
    setData(filterResult)
  }

  const onRemoveMultiProduct = useCallback(() => {
    try {
      const submitData: SubmitDataAssignProduct = {
        id,
        productIds: selectedRowKeys
      };
      removeProduct(submitData);
    } catch (error) {
      console.log(error)
    };
  }, [selectedRowKeys]);
  const onRemoveProduct = useCallback(
    (item: string) => {
      try {
        const submitData: SubmitDataAssignProduct = {
          id,
          productIds: [item],
        };
        removeProduct(submitData);
      } catch (error) {
        console.log(error);
      };
    },
    [id]
  );
  const columns: ColumnsType = useMemo(
    () => [
      {
        title: "Tên sản phẩm",
        dataIndex: "product",
        key: "product",
        render(product, record, index) {
          return (
            <Typography.Text strong>
              {get(product, "codeBySupplier", "")} - {get(product, "name", "")}
            </Typography.Text>
          );
        },
      },
      {
        title: selectedRowKeys?.length && canDelete ?  <Button size="small" onClick={onRemoveMultiProduct} type="primary">Gỡ</Button> : "" ,
        dataIndex: "productId",
        key: "productId",
        align: "center",
        width: 70,
        render(productId, record: any, index) {
          return !selectedRowKeys?.includes(productId) && canDelete ? (
            <Button
              size="small"
              loading={isSubmitLoading}
              onClick={() =>
                onRemoveProduct(record?.productId)
              }
              danger
            >
              Gỡ
            </Button>
          ) : (
            <></>
          );
        },
      },
    ],
    [id, isSubmitLoading, selectedRowKeys,canDelete,canUpdate, dataSource]
  );
  useEffect(() => {
    setSelectedRowKeys([]);
    setData([]);
  }, [dataSource]);

  const fetcher = (e: any) => {
    setKeyword(e.target.value);
    onTableChange(1,10);
  };
  const debounceFetcher = debounce(fetcher, 500)
  
  return (
    <>
      <TableAnt
        title={() => <Search allowClear onChange={debounceFetcher}/>}
        loading={isLoading}
        dataSource={data?.length ? data : dataSource}
        columns={columns}
        pagination={paging}
        onChange={onTableChange}
        size="small"
        scroll={{ y: 400 }}
        rowSelection={rowSelection}
        rowKey={(rc) => rc?.productId}
        footer={() => (
          <Flex justify={"end"}>
            <Typography.Text style={{ fontStyle: "italic" }} strong>
              Tổng sản phẩm:{" "}
              {selectedRowKeys?.length
                ? `${selectedRowKeys.length} / ${dataSource?.length}`
                : dataSource?.length}
            </Typography.Text>
          </Flex>
        )}
      />
    </>
  );
}
