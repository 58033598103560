import { get } from "lodash";
import requester from "~/api/requester";

const apis = {
    getAll: (query?: any) => requester.get(`/api/v1/sales-channel`, query),
    getById: (id?: any) => requester.get(`/api/v1/sales-channel/${id}`),
    create: (data?: any) => requester.post(`/api/v1/sales-channel`, data),
    update: (data?: any) => requester.put(`/api/v1/sales-channel/${get(data,'_id')}`, data),
    delete: (id?: any) => requester.delete(`/api/v1/sales-channel/${id}`),
    search: (query?: any) => requester.get(`/api/v1/sales-channel-search`, query),
    getOptions: (query?: any) => requester.get(`/api/v1/sale-channel-option`, query),
    getListProductById: ({id,...query} : any) => requester.get(`/api/v1/sales-channel-product-list/${id}`,query),
    addListProductById: (payload : any) => requester.post(`/api/v1/sales-channel-product-add`,payload),
    removeListProductById: ({id,...restBody} : any) => requester.delete(`/api/v1/sales-channel-product-remove/${id}`,restBody),
    updateListProductById: ({id,...restBody} : any) => requester.put(`/api/v1/sales-channel-product-update/${id}`,restBody),
    searchListProductById: ({id,...query} : any) => requester.get(`/api/v1/sales-channel-product-search/${id}`,query),
}
export default apis;
