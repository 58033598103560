import { Alert, Flex } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import ModalAnt from "~/components/Antd/ModalAnt";
import { useCumulativeDiscountValueQueryParams, useDeleteCumulativeDiscountValue, useGetCumulativeDiscountValues } from "../cumulativeDiscountValue.hook";
import { cumulativeDiscountValueBase } from "../cumulativeDiscountValue.modal";
import CumulativeDiscountValueSaleChannelForm from "./CumulativeDiscountValueSaleChannelForm";
import CumulativeDiscountValueSaleChannelItem from "./CumulativeDiscountValueSaleChannelItem";
type propsType = {
  id : string;
};
export default function CumulativeDiscountValue({
  id
}: propsType): React.JSX.Element {
  const [query] = useCumulativeDiscountValueQueryParams({id});
  const [data, loading] = useGetCumulativeDiscountValues(query);
  const [isSubmitLoading, onDelete] = useDeleteCumulativeDiscountValue();
  
  const [open, setOpen] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<cumulativeDiscountValueBase | null>();
  const onOpen = useCallback((dataUpdate_?: cumulativeDiscountValueBase) => {
      dataUpdate_ && setDataUpdate(dataUpdate_);
      setOpen(true);
    },
    []
  );
  const onClose = useCallback(() => {
    setDataUpdate(null);
    setOpen(false);
  }, []);

  return (
    <Flex vertical gap={10}>
      <Alert message="Chỉ có chiết khấu đã được lưu mới được thêm giá trị theo kênh" type="info" showIcon />
        <CumulativeDiscountValueSaleChannelItem
        onDelete={onDelete}
        loadingDelete={isSubmitLoading}
        loading={loading}
        dataSource={data}
        onOpen={onOpen}
        id={id}
      />
      <ModalAnt
        destroyOnClose
        title={dataUpdate ? "Cập nhật " : "Tạo mới " + "Giá theo kênh"}
        footer={null}
        centered
        open={open}
        onCancel={onClose}
      >
        <CumulativeDiscountValueSaleChannelForm
          id={id}
          dataUpdate={dataUpdate}
          onCancel={onClose}
          dataSource={data}
        />
      </ModalAnt>
    </Flex>
  );
}
