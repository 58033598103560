import { Select } from "antd";
import { SelectProps } from "antd/lib/index";
import React, { useMemo } from "react";
import { useFetchState } from "~/utils/hook";
import ProductGroupModule from "~/modules/productGroup";
import { get } from "lodash";

export default function ProductGroupSelect(
  props: SelectProps
): React.JSX.Element {
  const [productGroups, loading] = useFetchState({
    api: ProductGroupModule.api.getAllPublic,
    useDocs: false,
  });
  const options = useMemo(
    () =>
      productGroups?.map((item: any) => ({
        label: get(item, "name"),
        value: get(item, "_id"),
      })),
    [productGroups]
  );
  return (
    <Select
      popupMatchSelectWidth={false}
      allowClear
      placeholder="Nhóm sản phẩm"
      loading={loading}
      options={options}
      {...props}
    />
  );
}
