import { Button, Form, Radio } from "antd";
import React, { useEffect, useMemo } from "react";
import InputNumberAnt from "~/components/Antd/InputNumberAnt";
import { requireRules } from "~/constants/defaultValue";
import SaleChannelSelect from "~/modules/saleChannel/components/SaleChannelSelect";
import { useCreateCumulativeDiscountValue, useUpdateCumulativeDiscountValue } from "../cumulativeDiscountValue.hook";
import { cumulativeDiscountValueBase } from "../cumulativeDiscountValue.modal";
type propsType = {
  id : string;
  onCancel : () => void;
  dataUpdate ? : cumulativeDiscountValueBase | null;
  dataSource : cumulativeDiscountValueBase[]
};
export default function CumulativeDiscountValueSaleChannelForm({
  id,
  onCancel,
  dataUpdate,
  dataSource,
}: Partial<propsType>): React.JSX.Element {
  const [isSubmitLoading, onCreate] = useCreateCumulativeDiscountValue(onCancel);
  const [, onUpdate] = useUpdateCumulativeDiscountValue(onCancel);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    const submitData = {
      cumulativeDiscountId : id,
      ...values,
    };
    if (!!dataUpdate) {
      onUpdate({
        ...submitData,
        _id : dataUpdate._id,
      });
    } else {
      onCreate(submitData);
    }
  };
  useEffect(() => {
    if (dataUpdate) {
      form.setFieldsValue({
        ...dataUpdate,
        saleChannelId: dataUpdate?.saleChannelId?._id,
      });
    } else {
      form.resetFields();
    }
  }, [dataUpdate]);

  const SaleChannelSelectIds : any = useMemo(() => dataSource?.map((item) => item?.saleChannelId?._id),[dataSource]);
  return (
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        labelAlign="left"
        initialValues={{
          valueType: "VALUE",
        }}
      >
        <Form.Item name={"saleChannelId"} label="Kênh bán hàng" rules={requireRules}>
          <SaleChannelSelect valuesNeedDisabled={SaleChannelSelectIds}/>
        </Form.Item>
        <Form.Item name={"value"} label="Giá trị" 
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue(['valueType']) === 'VALUE') {
                if(value < 0){
                  return Promise.reject(new Error('Vui lòng nhập giá trị >= 0'));
                }
              }
              if (getFieldValue(['valueType']) === 'PERCENT') {
                if(value < -100){
                  return Promise.reject(new Error('Không được giảm quá âm 100%'));
                }
              
              }
              return Promise.resolve();
            
            }
          }),
          ...requireRules
        ]}
        >
          <InputNumberAnt />
        </Form.Item>
        <Form.Item name={"valueType"} label="Kiểu giá trị" rules={requireRules}>
          <Radio.Group size="small" buttonStyle="solid">
            <Radio.Button value="VALUE">Giá trị</Radio.Button>
            <Radio.Button value="PERCENT">%</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Button
          block
          loading={isSubmitLoading}
          type="primary"
          onClick={() => form.submit()}
        >
          {!!dataUpdate ? "Cập nhật" : "Tạo mới"}
        </Button>
      </Form>
  );
}
